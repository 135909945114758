import GatsbyImage from "gatsby-image"
import React, { FunctionComponent } from "react"
import styled from "styled-components"
import { textColor } from "../../../theming/theme-getters"
import MicroFeatureContent from "./micro-feature-content.interface"

interface Props extends MicroFeatureContent {
  readonly inFullFeature?: boolean
}

const Root = styled.div<{ inFullFeature: boolean }>`
  display: flex;
  flex-direction: column;
  max-width: 15.625rem;
  margin: 0 auto 3.75rem;
  text-align: center;

  @media (min-width: 750px) {
    ${({ inFullFeature }) =>
      inFullFeature &&
      `flex-direction: row; margin: 1.875rem 0 1.875rem; max-width: 100%; text-align: left; align-items: center;`}
  }
`

const Image = styled(GatsbyImage)<{ inFullFeature: boolean }>`
  width: 5.625rem;
  min-width: 5.625rem;
  height: 5.625rem;
  min-height: 5.625rem;
  border-radius: 50%;
  margin: 0 auto;
  @media (min-width: 750px) {
    ${({ inFullFeature }) => inFullFeature && `margin: 0 3.125rem 0 0;`}
  }
`

const Text = styled.p<{ inFullFeature: boolean }>`
  line-height: 1.5;
  font-size: 1.25rem;
  color: ${textColor("dark")};
  font-weight: 300;
  margin: 1.875rem 0 0;

  @media (min-width: 750px) {
    ${({ inFullFeature }) => inFullFeature && `margin: 0;`}
  }
`

const MicroFeatureItem: FunctionComponent<Props> = ({
  title,
  image: { description, fluid },
  inFullFeature = false,
}) => {
  return (
    <Root inFullFeature={inFullFeature}>
      <Image inFullFeature={inFullFeature} fluid={fluid} alt={description} />
      <Text inFullFeature={inFullFeature}>{title}</Text>
    </Root>
  )
}

export default MicroFeatureItem
