import React, { InputHTMLAttributes } from "react"
import styled from "styled-components"
import Tick from "../../../images/tick.svg"
import { backgroundColor, textColor } from "../../../theming/theme-getters"
import AppFunctionComponent from "../../../types/app-function-component.interface"
import { StyledLink } from "../../global-style.component"

const LabelContainer = styled.label`
  display: flex;
  align-items: flex-start;
  position: relative;

  a {
    ${StyledLink}
  }
`

const Input = styled.input`
  position: absolute;
  left: 3px;
  top: 3px;
`

const CheckMark = styled.div`
  margin-right: 6px;
  box-sizing: border-box;
  box-shadow: 0 0 20px 0 rgba(36, 36, 36, 0.13);
  border: solid 1px rgba(46, 91, 255, 0.08);
  border-radius: 2px;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: background 150ms;
  background: ${backgroundColor("light")};
  position: relative;
  input:checked + & {
    background: ${backgroundColor("dark")};
  }
`

const Content = styled.span`
  display: inline-block;
  margin-top: -5px;
  color: ${textColor("faded")};
`

const Checkbox: AppFunctionComponent<Omit<
  InputHTMLAttributes<HTMLInputElement>,
  "type"
>> = ({ children, ...inputProps }) => {
  return (
    <LabelContainer>
      <Input type="checkbox" {...inputProps} />
      <CheckMark>
        <Tick />
      </CheckMark>
      <Content>{children}</Content>
    </LabelContainer>
  )
}

export default Checkbox
