import React, { FunctionComponent } from "react"
import styled, { css } from "styled-components"
import { supportColor, textColor } from "../../../theming/theme-getters"
import LayoutBlock from "../../layout/layout-block.component"
import { FeatureContent } from "./feature-content.interface"
import { StyledLink } from "../../global-style.component"

const Title = styled.h3`
  margin-top: 0;
  padding-top: 2rem;
  position: relative;
  font-weight: 600;
  font-size: 2rem;
  line-height: 1.38;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    width: 54px;
    height: 2px;
    background-color: hotpink; // TODO: those colors should be configurable
  }
`

const Container = styled.section<{ fancy: boolean }>`
  padding: 2rem 0;
  ${({ fancy }) =>
    fancy && "background-image: linear-gradient(to top, #f6f7fa, #fff 20%)"};

  @media (min-width: 48rem) {
    padding: 3.75rem 0;
  }

  &:first-child {
    ${Title} {
      &::before {
        background-color: transparent;
      }
    }
  }
`

const Block = styled.div`
  width: 100%;
  @media (min-width: 750px) {
    width: 50%;
  }
`

const Content = styled(Block)`
  align-self: center;

  a {
    ${StyledLink}
  }
`

const Slot = styled(Block)``

const blockMargin = "2.75rem"

const leftRowPosition = css`
  flex-direction: row;
  ${Content} {
    margin-right: ${blockMargin};
  }
`

const rightRowPosition = css`
  flex-direction: row-reverse;
  ${Content} {
    margin-left: ${blockMargin};
  }
`

const leftColumnPosition = css`
  flex-direction: column;
  ${Content} {
    margin-bottom: ${blockMargin};
  }
`

const rightColumnPosition = css`
  flex-direction: column-reverse;
  ${Content} {
    margin-top: ${blockMargin};
  }
`

const InnerContent = styled(LayoutBlock)<{ contentPosition: "left" | "right" }>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ contentPosition }) =>
    contentPosition === "right" ? rightColumnPosition : leftColumnPosition};

  @media (min-width: 750px) {
    ${({ contentPosition }) =>
      contentPosition === "right" ? rightRowPosition : leftRowPosition};
  }
`

const Paragraph = styled.div`
  p {
    font-weight: 100;
    font-size: 1.25rem;
    color: ${textColor("paragraph")};
  }

  ol,
  ul {
    color: ${textColor("paragraph")};
    font-size: 1.25rem;
    font-weight: 100;
  }

  ol {
    margin: 3.75rem 0;
    counter-reset: my-counter;
    list-style: none;
    padding-left: 3.75rem;
  }

  ol li {
    margin: 0 0 2.5rem 0;
    counter-increment: my-counter;
    position: relative;
  }

  ul li {
    + li {
      margin-top: 1rem;
    }
  }

  ol li::before {
    content: counter(my-counter);
    color: ${textColor("inverted")};
    position: absolute;
    --size: 40px;
    left: calc(-1 * var(--size) - 25px);
    line-height: var(--size);
    width: var(--size);
    height: var(--size);
    top: -5px;
    background: ${supportColor("actionable")};
    border-radius: 50%;
    text-align: center;
  }
`

const Feature: FunctionComponent<FeatureContent> = ({
  title,
  content = "",
  contentSlot,
  children,
  contentPosition,
  fancy = false,
}) => {
  return (
    <Container fancy={fancy}>
      <InnerContent contentPosition={contentPosition}>
        <Content>
          {title && <Title data-testid="title">{title}</Title>}
          {content && (
            <Paragraph
              data-testid="content"
              dangerouslySetInnerHTML={{ __html: content }}
            />
          )}
          {contentSlot}
        </Content>
        <Slot>{children}</Slot>
      </InnerContent>
    </Container>
  )
}

export default Feature
