import { graphql } from "gatsby"
import React, { FunctionComponent } from "react"
import Page from "../components/base/page/page.component"
import PageProps from "../types/page-props.interface"

const PageTemplate: FunctionComponent<PageProps> = ({
  data: { page },
  location: { pathname },
}) => {
  return <Page path={pathname} {...page} />
}

export default PageTemplate

export const query = graphql`
  query Page($id: String!) {
    page: contentfulPage(id: { eq: $id }) {
      ...PageFragment
    }
  }
`
