import React, { FunctionComponent } from "react"
import styled from "styled-components"
import CarIcon from "../../../images/car.svg"
import { textColor } from "../../../theming/theme-getters"
import UniqueComponentContent from "../../common/unique-component/unique-component-content.interface"

const Section = styled.section`
  padding: 1.25rem;
`

const Root = styled.div`
  padding: 3.75rem 1.25rem;
  border-radius: 6px;
  box-shadow: 0 0 20px 0 rgba(36, 36, 36, 0.13);
  border: solid 1px rgba(46, 91, 255, 0.08);
  text-align: center;
  max-width: 73.75rem;
  box-sizing: border-box;
  margin: 0 auto;

  @media (min-width: 56.25rem) {
    padding: 3.75rem 12.5rem;
  }

  @media (min-width: 65.625rem) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
`

const Title = styled.p`
  font-size: 1.25rem;
  font-weight: 300;
  margin: 0 0 3.5rem;
  width: 100%;
`

const SingleColumn = styled.div`
  @media (min-width: 56.25rem) {
    padding-top: 2rem;
  }
  svg {
    margin-bottom: 3.75rem;
  }
`

const AreaText = styled.p`
  font-size: 1.25rem;
  font-weight: 600;
  color: ${textColor("dark")};
  margin: 0 0 0.5rem;

  sup {
    font-size: 0.75rem;
    font-weight: normal;
  }
`

const Text = styled.p`
  color: ${textColor("paragraph")};
  font-weight: 300;
  font-size: 1rem;
  margin: 0;
`

const Vs = styled.p`
  margin: 3.75rem 0 3rem;
  font-size: 2.25rem;

  @media (min-width: 56.25rem) {
    font-weight: 600;
    font-size: 2rem;
  }
`

const MultipleColumn = styled.div``

const Icons = styled.div`
  display: inline-grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 1rem;
  grid-template-rows: repeat(3, 1fr);
  grid-row-gap: 1.5625rem;
  justify-items: center;
  align-items: center;
  margin-bottom: 2.25rem;

  @media (min-width: 350px) {
    grid-column-gap: 1.5625rem;
  }

  @media (min-width: 56.25rem) {
    grid-column-gap: 1.875rem;
    grid-row-gap: 1.875rem;
    margin-bottom: 1.25rem;
  }

  svg {
    width: 34px;
    height: 28px;

    @media (min-width: 56.25rem) {
      width: 39.6px;
      height: 32px;
    }
  }
`

const BigCard: FunctionComponent<UniqueComponentContent> = ({ title }) => (
  <Section>
    <Root>
      <Title data-testid="title">{title}</Title>
      <SingleColumn>
        <CarIcon />
        <AreaText>
          8 m<sup>2</sup>
        </AreaText>
        <Text>zajmowanej przestrzeni</Text>
      </SingleColumn>

      <Vs>VS</Vs>
      <MultipleColumn>
        <Icons>
          {new Array(15).fill(0).map((_, index) => (
            <CarIcon key={index} />
          ))}
        </Icons>
        <AreaText>
          40-120 m<sup>2</sup>
        </AreaText>
        <Text>zajmowanej przestrzeni</Text>
      </MultipleColumn>
    </Root>
  </Section>
)

export default BigCard
