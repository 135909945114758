import React, { useContext } from "react"
import styled from "styled-components"
import Appnroll from "../../../images/appnroll.svg"
import AppFunctionComponent from "../../../types/app-function-component.interface"
import { SiteConfigContext } from "../../context/site-config/site-config.context"

const Name = styled.span`
  font-size: 0;
`

const Logo = styled(Appnroll)`
  margin-bottom: -8px;
  margin-left: 2px;
`

export const MadeByView: AppFunctionComponent<{ anrLink: string }> = ({
  className,
  anrLink,
}) => {
  return (
    <span className={className}>
      Made by{" "}
      <a href={anrLink}>
        <Name>App'n'roll</Name>
        <Logo />
      </a>
    </span>
  )
}

const MadeBy: AppFunctionComponent = (props) => {
  const { anrLink } = useContext(SiteConfigContext)
  return <MadeByView {...props} anrLink={anrLink} />
}

export default MadeBy
