import React, { ChangeEvent, FormEvent, FunctionComponent } from "react"
import styled from "styled-components"
import text from "../../../functions/text"
import PendableButton from "../../base/button/pendable-button.component"
import CardContainer from "../../base/card-container/card-container"
import Checkbox from "../../base/checkbox/checkbox.component"
import Label from "../../base/label/label.component"
import SelectInput from "../../base/select-input/select-input.component"
import TextAreaInput from "../../base/text-area-input/text-area-input.component"
import TextInput from "../../base/text-input/text-input.component"
import LayoutBlock from "../../layout/layout-block.component"
import ContactFormContent from "./contact-form-content.interface"
import {
  ContactInput,
  InputChangeAction,
  InputsValues,
  TextInputs,
} from "./contact-form-inputs.type"
import Text from "../../base/text/text.component"

const Container = styled(LayoutBlock)`
  margin-bottom: 6rem; // TODO: should be the footer that creates the margin.
`

const FormContainer = styled(CardContainer)``

const Form = styled.form``

const FormTitle = styled.h3`
  font-size: 1.5rem;
  font-weight: 300;
`

const Inputs = styled.div`
  margin: 0;
  border: none;
  padding: 0;
  display: flex;
  flex-direction: column;

  > * {
    margin-bottom: 2rem;
  }

  @media (min-width: 62rem) {
    margin-bottom: 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 2rem;

    > * {
      margin-bottom: 0;
    }

    > :last-child {
      grid-column: 1 / 4;
    }
  }
`

const Agreements = styled.div`
  display: flex;
  flex-direction: column;
  > * {
    margin-bottom: 2rem;
  }
`

const AgreementText = styled.span`
  font-size: 0.6875rem;
`

const SubmitButton = styled(PendableButton)`
  margin: 0 auto 2rem;
  width: 80%;
  display: block;
  @media (min-width: 43.75rem) {
    display: initial;
    width: initial;
    margin-top: 2rem;
  }
`

interface Props extends ContactFormContent {
  readonly onSubmit?: () => void
  readonly onInputChange?: (changeProps: InputChangeAction) => void
  readonly values: Readonly<InputsValues>
  readonly pending?: boolean
  readonly error?: boolean
  readonly done?: boolean
}

const ContactFormView: FunctionComponent<Props & { className?: string }> = ({
  onSubmit = () => null,
  onInputChange = () => null,
  pending = false,
  done = false,
  error = false,
  values,
  title,
  topics,
  agreements,
  errorMessage,
  sendButtonText,
  successMessage,
  nameLabel,
  emailLabel,
  phoneLabel,
  topicLabel,
  messageLabel,
  className = "",
}) => {
  const onFormSubmit = (event: FormEvent) => {
    event.preventDefault()
    if (!pending) {
      onSubmit()
    }
  }
  const onInputValueChange = (input: TextInputs) => {
    return ({
      target: { value },
    }: ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >) => onInputChange({ input, value })
  }
  const onAgreementValueChange = (index: number) => () =>
    onInputChange({ input: ContactInput.Agreements, index })
  const inputDisabled = pending || done
  return (
    <Container>
      <FormContainer>
        <FormTitle>{title}</FormTitle>
        <Form
          onSubmit={onFormSubmit}
          data-testid="form"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          method="POST"
          action="/?no-cache=1"
          name="contact"
        >
          <Inputs>
            <Label text={nameLabel}>
              <TextInput
                disabled={inputDisabled}
                type="text"
                onChange={onInputValueChange(ContactInput.Name)}
                value={values[ContactInput.Name]}
                required
                name="name"
              />
            </Label>
            <Label text={emailLabel}>
              <TextInput
                disabled={inputDisabled}
                type="email"
                onChange={onInputValueChange(ContactInput.Email)}
                value={values[ContactInput.Email]}
                required
                name="email"
              />
            </Label>
            <Label text={phoneLabel}>
              <TextInput
                disabled={inputDisabled}
                type="text"
                onChange={onInputValueChange(ContactInput.Phone)}
                value={values[ContactInput.Phone]}
                name="phone"
              />
            </Label>
            <Label text={topicLabel}>
              <SelectInput
                disabled={inputDisabled}
                onChange={onInputValueChange(ContactInput.Topic)}
                value={values[ContactInput.Topic]}
                required={true}
                name="topic"
              >
                {topics.map((topic, index) => (
                  <option value={topic} key={index}>
                    {topic}
                  </option>
                ))}
              </SelectInput>
            </Label>
            <Label text={messageLabel}>
              <TextAreaInput
                className={className}
                disabled={inputDisabled}
                onChange={onInputValueChange(ContactInput.Message)}
                value={values[ContactInput.Message]}
                required
                name="message"
              />
            </Label>
          </Inputs>
          <Agreements>
            {/* Hidden input is required in order to make Netlify catch the value. */}
            <input type="hidden" name="selectedAgreements" />
            {agreements.map((agreement, index) => (
              <Checkbox
                key={index}
                required
                disabled={inputDisabled}
                checked={values[ContactInput.Agreements][index]}
                onChange={onAgreementValueChange(index)}
              >
                <AgreementText>
                  <Text>{agreement}</Text>
                </AgreementText>
              </Checkbox>
            ))}
          </Agreements>
          <SubmitButton pending={pending} disabled={done}>
            {sendButtonText}
          </SubmitButton>
          {error && <p>{text(errorMessage)}</p>}
          {done && <p>{text(successMessage)}</p>}
        </Form>
      </FormContainer>
    </Container>
  )
}

export default ContactFormView
