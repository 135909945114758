import React, { FunctionComponent, useState } from "react"
import styled from "styled-components"
import Arrow from "../../../images/arrow-down.svg"
import ExternalLinkIcon from "../../../images/external-link.svg"
import { supportColor } from "../../../theming/theme-getters"
import { SmallCardComponent } from "../../../types/components.interface"
import LayoutBlock from "../../layout/layout-block.component"
import CardComponent, { CardBox } from "../card/card.component"

const Container = styled(LayoutBlock)`
  max-width: 65.625rem;
  margin: 0 auto;
  display: none;
  @media (min-width: 35rem) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
  }

  @media (min-width: 48rem) {
    grid-template-columns: repeat(3, 1fr);
  }
`

export const OverlayLink = styled.a`
  opacity: 0;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: -20px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  overflow: hidden;
  padding: 20px;
  cursor: pointer;
  transition: all 0.4s ease-in;
`

export const Card = styled(CardComponent)`
  display: flex;
  flex-direction: column;
  text-align: center;
  flex: 1 1 0%;
  margin-bottom: 1.25rem;

  ${CardBox} {
    display: flex;
    flex-direction: column;
    flex: 1 1 0%;
    margin: 0;
    overflow: hidden;
    justify-content: space-between;

    h4 {
      font-weight: 300;
      font-size: 1.25rem;
    }
  }

  a {
    text-decoration: none;
    color: ${supportColor("actionable")};

    &:hover {
      text-decoration: underline;
    }
  }
`

export const Wrapper = styled.div`
  position: relative;
  display: flex !important;
  flex-direction: column;
  flex: 1 1 0%;

  &:hover {
    ${OverlayLink} {
      opacity: 1;
      top: 0;
      transition: all 0.4s ease-in;
    }

    ${Card} {
      a {
        text-decoration: underline;
      }
    }
  }
`

const Button = styled.button`
  display: none;

  @media (min-width: 35rem) {
    font-weight: 300;
    font-size: 1.25rem;
    border: 0;
    background: transparent;
    cursor: pointer;
    margin: 3.75rem auto 0;
    display: flex;
    align-items: center;
  }
`

const ArrowIcon = styled(Arrow)`
  margin-left: 1rem;
  margin-top: 6px;
`

interface Props {
  readonly cards: readonly SmallCardComponent[]
}

interface ViewProps extends Props {
  readonly allVisible: boolean
  readonly onMoreClick: () => void
}

export const SmallCardsView: FunctionComponent<ViewProps> = ({
  cards,
  allVisible,
  onMoreClick,
}) => {
  const cardsLimit = 6
  const visibleCards = allVisible ? cards : cards.slice(0, cardsLimit)
  return (
    <>
      <Container>
        {visibleCards.map((card, index) => (
          <Wrapper key={index}>
            <Card {...card} />
            <OverlayLink href={card.linkUrl}>
              <ExternalLinkIcon />
            </OverlayLink>
          </Wrapper>
        ))}
      </Container>
      {!allVisible && cards.length > cardsLimit && (
        <Button onClick={onMoreClick}>
          Wyświetl więcej <ArrowIcon />
        </Button>
      )}
    </>
  )
}

const SmallCards: FunctionComponent<Props> = ({ cards }) => {
  const [allVisible, setAllVisible] = useState(false)
  const showMore = () => setAllVisible(true)
  return (
    <SmallCardsView
      cards={cards}
      allVisible={allVisible}
      onMoreClick={showMore}
    />
  )
}

export default SmallCards
