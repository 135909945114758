import { Link } from "gatsby"
import React, { FunctionComponent } from "react"
import styled from "styled-components"
import MuuvLogo from "../../../images/vooom-logo-color-footer.svg"
import {
  backgroundColor,
  minBreakpoint,
  textColor,
} from "../../../theming/theme-getters"
import NavigationItem from "../../../types/navigation-item.interface"
import LayoutBlock from "../../layout/layout-block.component"
import MadeBy from "../made-by/made-by.component"
import { FormattedMessage } from "react-intl"

const Container = styled.footer<{ additionalBackground: string | undefined }>`
  padding-bottom: 3.5rem;
  background-color: ${backgroundColor("footer")};
  color: ${textColor("paragraph")};

  &::before {
    content: "";
    display: block;
    height: 60px;
    background-color: ${({ additionalBackground = "#fff" }) =>
      additionalBackground};
  }
`

const BottomFooter = styled.aside`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media (min-width: ${minBreakpoint("tablet")}) {
    margin-top: 5.5rem;
    flex-direction: row;
    > * {
      width: 33%;
    }
  }
`

const FooterMadeBy = styled(MadeBy)`
  order: 2;
  margin: 0 auto 1rem;
  text-align: right;
  @media (min-width: ${minBreakpoint("tablet")}) {
    order: 3;
    margin: 0;
  }
`

const Copyrights = styled.p`
  order: 3;
  margin: 0;
  @media (min-width: ${minBreakpoint("tablet")}) {
    order: 1;
  }
`

const Logo = styled(MuuvLogo)`
  display: block;
  margin: 0 auto;
  transform: translateY(-50%);
  height: 80px;
  width: 80px;

  @media (min-width: 48rem) {
    width: 100px;
    height: 100px;
  }
`

const AsideNavigation = styled.nav`
  order: 1;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 3rem;

  @media (min-width: ${minBreakpoint("tablet")}) {
    order: 2;
    margin-bottom: 0;
    flex-direction: row;
    justify-content: center;
  }
`

const AsideLink = styled(Link)`
  color: inherit;
  text-decoration: none;
  margin-bottom: 1rem;
  @media (min-width: ${minBreakpoint("tablet")}) {
    margin-bottom: 0;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
`

export interface Props {
  additionalBackground?: string
  asideNavigationItems: NavigationItem[]
}

// TODO add <nav> for semantics.
const Footer: FunctionComponent<Props> = ({
  asideNavigationItems = [],
  additionalBackground,
  children,
}) => {
  return (
    <Container additionalBackground={additionalBackground}>
      <Logo />
      <LayoutBlock>
        {children}
        <BottomFooter>
          <AsideNavigation>
            {asideNavigationItems.map(({ navigationName, path }) =>
              // Allows to use separate domain links (eg to vooom.pl).
              path.startsWith("http") ? (
                <AsideLink href={path} as={"a"} key={path}>
                  {navigationName}
                </AsideLink>
              ) : (
                <AsideLink to={path} key={path}>
                  {navigationName}
                </AsideLink>
              )
            )}
          </AsideNavigation>
          <Copyrights>
            © {new Date().getFullYear()}{" "}
            <FormattedMessage id="all.right.reserved" />
          </Copyrights>
          <FooterMadeBy />
        </BottomFooter>
      </LayoutBlock>
    </Container>
  )
}

export default Footer
