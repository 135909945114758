import styled, { css } from "styled-components"
import { supportColor, textColor } from "../../../theming/theme-getters"

export const inputBorderStyle = css`
  border-bottom: 2px solid ${supportColor("inputBorder")};

  &:active,
  &:focus,
  &::-moz-focus-inner {
    border-bottom-color: ${textColor("paragraph")};
  }

  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }

  &:disabled {
    &:active,
    &:focus {
      border-bottom-color: ${supportColor("inputBorder")};
    }
  }
`

const TextInput = styled.input<{ variant?: "normal" | "plain" }>`
  border: none;
  display: block;
  font-size: 1.25rem;
  line-height: 1;
  transition: 250ms border-bottom-color;
  padding: 0;
  -webkit-appearance: none;
  border-radius: 0;

  &::placeholder {
    color: transparent;
    transition: color 250ms;
  }

  &:invalid {
    box-shadow: none;
  }

  ${({ variant = "normal" }) => variant === "normal" && inputBorderStyle}}

  &:active, &:focus {
    border-bottom-color: ${textColor("paragraph")};
    &::placeholder {
      color: ${textColor("paragraph")};
    }
  }

  &:focus {
    outline: none;
  }
`

export default TextInput
