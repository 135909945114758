import React, { FunctionComponent } from "react"
import styled from "styled-components"
import ArrowIcon from "../../../images/arrow-right.svg"

const StyledArrow = styled.div`
  opacity: 0.9;
  &::before {
    display: none;
  }

  @media (hover: hover) {
    &:hover {
      opacity: 1;
    }
  }

  &:active,
  &:focus {
    opacity: 1;
  }
`

const ArrowIconLeft = styled(ArrowIcon)`
  transform: rotate(180deg);
`

interface Props {
  className?: string
  onClick?: () => void
}

export const ArrowRight: FunctionComponent<Props> = ({
  onClick,
  className,
}) => {
  return (
    <StyledArrow className={className} onClick={onClick}>
      <ArrowIcon />
    </StyledArrow>
  )
}

export const ArrowLeft: FunctionComponent<Props> = ({ onClick, className }) => {
  return (
    <StyledArrow className={className} onClick={onClick}>
      <ArrowIconLeft />
    </StyledArrow>
  )
}
