import React, { FunctionComponent } from "react"
import styled from "styled-components"
import MicroFeatureContent from "./micro-feature-content.interface"
import MicroFeatureItem from "./micro-feature-item.component"

interface Props {
  readonly items: MicroFeatureContent[]
  readonly inFullFeature?: boolean
}

const Root = styled.div<{ inFullFeature: boolean }>`
  display: grid;
  ${({ inFullFeature }) =>
    inFullFeature
      ? `
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(3, 1fr);
      `
      : `grid-template-columns: repeat(1, 1fr);
  padding: 0 1.25rem;
  margin: 0 auto;
  max-width: 73.75rem;
  
    @media (min-width: 35rem) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 48rem) {
    grid-template-columns: repeat(4, 1fr);
  }
  `};
`

const MicroFeature: FunctionComponent<Props> = ({
  items,
  inFullFeature = false,
}) => (
  <Root inFullFeature={inFullFeature}>
    {items.map((item, index) => (
      <MicroFeatureItem
        inFullFeature={inFullFeature}
        key={index}
        title={item.title}
        image={item.image}
      />
    ))}
  </Root>
)

export default MicroFeature
