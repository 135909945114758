import React, { cloneElement, ReactElement, useState } from "react"
import AppFunctionComponent from "../../../types/app-function-component.interface"
import LabelElement from "./label-element"

interface Props {
  readonly text?: string
  readonly onFocus?: () => void
  readonly onBlur?: () => void
  readonly variant?: "normal" | "stylish"
}

const Label: AppFunctionComponent<Props> = ({
  text,
  children,
  onFocus = () => null,
  onBlur = () => null,
  variant = "normal",
  className,
}) => {
  const [active, setActive] = useState(false)
  const onInputFocus = () => {
    setActive(true)
    onFocus()
  }
  const onInputBlur = () => {
    setActive(false)
    onBlur()
  }
  return (
    <LabelElement
      active={active}
      text={text}
      variant={variant}
      className={className}
    >
      {children &&
        cloneElement(children as ReactElement, {
          onFocus: onInputFocus,
          onBlur: onInputBlur,
        })}
    </LabelElement>
  )
}

export default Label
