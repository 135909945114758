import GatsbyImage, { FluidObject } from "gatsby-image"
import React, { FunctionComponent } from "react"
import styled, { css } from "styled-components"
import ImageContent from "./image.interface"

const Root = styled.div`
  padding: 0 1.25rem;
  max-width: 73.75rem;
  margin: 0 auto;
`

// These styles fix a problem in `gatsby-image` where using
// art direction with images that have different aspect ratios
// defaults to using the aspect ratio of the first image passed
// to `fluidSources`. This is hardcoded to work for two images,
// but could be generalized to work for more. Ideally this
// will be fixed upstream in the future.
const Img = styled(GatsbyImage)<{ images: FluidObject[] }>`
  ${({ images }) => css`
    & > div:first-child {
      padding-bottom: ${100 / images[0].aspectRatio}% !important;

      @media (min-width: 768px) {
        padding-bottom: ${100 / images[1].aspectRatio}% !important;
      }
    }
  `}
`

const Image: FunctionComponent<ImageContent> = ({ image, imageMobile }) => {
  let images: FluidObject[]
  if (imageMobile) {
    images = [
      imageMobile.fluid,
      { ...image.fluid, media: `(min-width: 768px)` },
    ]
  } else {
    images = [image.fluid]
  }
  return (
    <Root>
      <Img images={images} fluid={images} alt={image.description} />
    </Root>
  )
}

export default Image
