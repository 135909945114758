import Component, { MapComponent } from "../types/components.interface"

const uniqueComponents: Record<string, [number, Component][]> = {
  "/": [
    [
      0, // Hero is removed from components list.
      {
        internal: {
          type: "AppHomePageMap",
        },
      } as MapComponent,
    ],
  ],
}

export const addUniqueComponentsForPath = (
  components: readonly Component[],
  path: string
) => {
  if (uniqueComponents[path]) {
    return uniqueComponents[path].reduce(
      (allComponents, [index, component]) => [
        ...allComponents.slice(0, index),
        component,
        ...allComponents.slice(index),
      ],
      components.slice()
    )
  } else {
    return components
  }
}
