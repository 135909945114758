export enum CallToActionType {
  Main = "main",
  Supplementary = "supplementary",
}

export default interface CallToActionContent {
  readonly buttonText: string
  readonly buttonTarget: string
  readonly callToAction: string
  readonly type: CallToActionType
  readonly features?: readonly string[]
}
