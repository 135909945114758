import styled from "styled-components"
import { inputBorderStyle } from "../text-input/text-input.component"

export const TextAreaInput = styled.textarea.attrs({ rows: 50 })`
  border: 0;
  height: 100px;
  ${inputBorderStyle};
  font-size: 1.25rem;
  font-family: inherit;
  padding: 0;
  -webkit-appearance: none;
  border-radius: 0;

  &:focus {
    outline: none;
  }

  &:required {
    box-shadow: none;
  }
  &:invalid {
    box-shadow: none;
  }
`

export default TextAreaInput
