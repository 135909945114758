import { ContactInput, InputsValues } from "./contact-form-inputs.type"

const sendMessage = async (
  state: Readonly<InputsValues>,
  agreements: readonly string[]
) => {
  const selectedAgreements: string = state[ContactInput.Agreements]
    .map((value, index) => (value ? agreements[index] : value))
    .filter((value) => value)
    .join("\n\n")
  try {
    const { status } = await fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({
        "form-name": "contact",
        name: state[ContactInput.Name],
        phone: state[ContactInput.Phone],
        email: state[ContactInput.Email],
        topic: state[ContactInput.Topic],
        message: state[ContactInput.Message],
        selectedAgreements,
      }),
    })
    if (status >= 400) {
      return {
        error: true,
        ok: false,
      }
    } else {
      return {
        error: false,
        ok: true,
      }
    }
  } catch (error) {
    return {
      error: true,
      ok: false,
    }
  }
}

export default sendMessage
