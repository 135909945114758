import React, { FunctionComponent } from "react"
import { ThemeProvider } from "styled-components"
import { Normalize } from "styled-normalize"
import theme from "../../theming/theme"
import Cookies from "../common/cookies/cookies.component"
import SiteConfigProvider from "../context/site-config/site-config.context"
import GlobalStyle from "../global-style.component"
import SEO from "../seo"
import { IntlProvider } from "react-intl"

export interface LayoutProps {
  readonly title?: string
  readonly seoTitle?: string
  readonly description?: string
  readonly footerAdditionalBackground?: string
  readonly withSeoTitleTemplate?: boolean
  readonly seoImage?: string
}

const locale = process.env.GATSBY_SITE_LANG === "pl" ? "pl" : "en"
const messages =
  process.env.GATSBY_SITE_LANG === "pl"
    ? require("../../locale/pl-PL.json")
    : require("../../locale/en.json")

const RootLayout: FunctionComponent<LayoutProps> = ({
  children,
  title,
  seoTitle,
  description,
  withSeoTitleTemplate,
  seoImage,
}) => {
  return (
    <IntlProvider locale={locale} messages={messages}>
      <ThemeProvider theme={theme}>
        <SiteConfigProvider>
          <SEO
            title={seoTitle || title}
            lang={locale}
            description={description}
            withSeoTitleTemplate={withSeoTitleTemplate}
            image={seoImage}
          />
          <Normalize />
          <GlobalStyle />
          {children}
          <Cookies />
        </SiteConfigProvider>
      </ThemeProvider>
    </IntlProvider>
  )
}

export default RootLayout
