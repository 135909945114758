import React, { FunctionComponent } from "react"
import styled from "styled-components"
import EmptyStar from "../../../images/empty-star.svg"
import FullStar from "../../../images/full-star.svg"
import { RatingRange } from "./rating-range.type"

const Container = styled.span`
  display: inline-block;
  > * + * {
    margin-left: 0.3rem;
  }
`

interface Props {
  readonly rating: RatingRange
}

const Rating: FunctionComponent<Props> = ({ rating }) => {
  return (
    <Container>
      {Array.from({ length: 5 as RatingRange }).map((_, index) => {
        if (index >= rating) {
          return <EmptyStar key={index} />
        } else {
          return <FullStar key={index} />
        }
      })}
    </Container>
  )
}

export default Rating
