import GatsbyImage from "gatsby-image"
import React, { FunctionComponent } from "react"
import styled, { css } from "styled-components"
import QuoteImage from "../../../images/quote.svg"
import { backgroundColor, textColor } from "../../../theming/theme-getters"
import QuoteContent from "./quote-content.interface"

const QuoteContainer = styled.blockquote<{ half: boolean }>`
  position: relative;
  padding: 4rem 2rem 2rem;
  border-radius: 36px;
  background-color: ${backgroundColor("quote")};
  text-align: center;
  color: ${textColor("inverted")};
  font-weight: 100;
  font-family: inherit;
  margin: 0 0.5rem 3rem;

  @media (min-width: 48rem) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    flex-basis: 0;
    align-items: center;
    padding: 5.625rem 8.57rem 3.75rem;
  }

  b,
  strong {
    color: ${textColor("inverted")};
  }

  ${({ half }) =>
    half &&
    css`
      @media (min-width: 48rem) {
        width: 50%;
        padding: 4rem 2rem 2rem;
      }
    `}
`

const QuoteDecoration = styled(QuoteImage)`
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translate(-50%);
`

const Cite = styled.cite`
  font-size: 1.25rem;
  font-style: normal;
  color: ${textColor("paragraph")};

  b,
  strong {
    color: ${textColor("paragraph")};
  }
`

const Content = styled.div`
  p {
    margin: 0 0 2rem;
    font-size: 1.25rem;

    @media (min-width: 43.75rem) {
      font-size: 1.5rem;
    }

    b,
    strong {
      color: ${textColor("inverted")};
    }
  }
`

const Image = styled(GatsbyImage)`
  width: 100px;
  margin: 0 auto;
`

interface Props extends QuoteContent {
  readonly withSibling?: boolean
}

const Quote: FunctionComponent<Props> = ({
  content,
  author,
  authorImage,
  withSibling = false,
}) => {
  const quoteContent = typeof content === "string" ? <p>{content}</p> : content
  return (
    <QuoteContainer data-testid="quote" half={withSibling}>
      <QuoteDecoration />
      <Content data-testid="content">{quoteContent}</Content>
      {(author || authorImage) && (
        <Cite>
          {authorImage && (
            <Image fluid={authorImage.fluid} alt={authorImage.description} />
          )}
          {author && <span data-testid="author">—{author}</span>}
        </Cite>
      )}
    </QuoteContainer>
  )
}

export default Quote
