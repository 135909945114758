import React, { FunctionComponent } from "react"
import styled from "styled-components"
import LayoutBlock from "../../layout/layout-block.component"

const Container = styled(LayoutBlock)`
  margin-bottom: 4rem; // FIXME: component shouldn't know it has a footer below.
`

interface Props {
  readonly html: string
}

const Document: FunctionComponent<Props> = ({ html }) => {
  return <Container dangerouslySetInnerHTML={{ __html: html }} />
}

export default Document
