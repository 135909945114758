import React, { FunctionComponent } from "react"
import styled, { css } from "styled-components"
import ClockIconImg from "../../../images/clock.svg"
import SuccessIconImg from "../../../images/success.svg"
import PasswordResetIconContent from "./password-reset-icon.content"

const iconStyles = css`
  height: 100px;
  width: 100px;
  display: block;
  margin: 3rem auto 0;
`

const SuccessIcon = styled(SuccessIconImg)`
  ${iconStyles}
`
const ClockIcon = styled(ClockIconImg)`
  ${iconStyles}
`

const PasswordResetIcon: FunctionComponent<PasswordResetIconContent> = ({
  name,
}) => {
  switch (name) {
    case "timeout":
      return <ClockIcon />
    case "success":
      return <SuccessIcon />
  }
}

export default PasswordResetIcon
