import React, { FunctionComponent } from "react"
import BigCard from "../../maas/big-card/big-card.component"
import UniqueComponentContent from "./unique-component-content.interface"

const UniqueComponentResolver: FunctionComponent<UniqueComponentContent> = (
  uniqueComponentProps
) => {
  if (uniqueComponentProps.identifier === "big-cars-card") {
    return <BigCard {...uniqueComponentProps} />
  } else {
    return null
  }
}

export default UniqueComponentResolver
