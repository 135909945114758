import React, { FunctionComponent } from "react"
import SmallCards, { Card, OverlayLink, Wrapper } from "./small-cards.component"
import styled from "styled-components"
import { SmallCardComponent } from "../../../types/components.interface"
import ExternalLinkIcon from "../../../images/external-link.svg"
import SmallCardsSlider from "../small-cards-slider/small-cards-slider.component"

interface Props {
  readonly cards: readonly SmallCardComponent[]
}

const StyldedSmallCards = styled(SmallCards)`
  display: none;
  @media (min-width: 35rem) {
    display: block;
  }
`

const StyledSlider = styled(SmallCardsSlider)`
  @media (min-width: 35rem) {
    display: none;
  }
`

const HrWrapper = styled.div`
  padding: 0 1rem;
`
const Hr = styled.div`
  height: 2px;
  max-width: 64.375rem;
  margin: 3.75rem auto 0;
  background-color: #d0d0d0;
`

const SmallCardsContainer: FunctionComponent<Props> = ({ cards }) => (
  <>
    <StyldedSmallCards cards={cards} />
    <StyledSlider>
      {cards.map((card, index) => (
        <Wrapper key={index}>
          <Card {...card} />
          <OverlayLink href={card.linkUrl}>
            <ExternalLinkIcon />
          </OverlayLink>
        </Wrapper>
      ))}
    </StyledSlider>
    <HrWrapper>
      <Hr />
    </HrWrapper>
  </>
)

export default SmallCardsContainer
