import React, { InputHTMLAttributes } from "react"
import styled from "styled-components"
import AppFunctionComponent from "../../../types/app-function-component.interface"
import { inputBorderStyle } from "../text-input/text-input.component"

const Container = styled.div`
  position: relative;

  &::before,
  &::after {
    content: "";
    position: absolute;
    right: 3px;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    width: 0;
    height: 0;
  }

  &::before {
    top: 10px;
    border-bottom: 5px solid currentColor;
  }

  &::after {
    top: 17px;
    border-top: 5px solid currentColor;
  }
`

const Select = styled.select`
  position: relative;
  box-sizing: border-box;
  border: none;
  border-radius: 0;
  padding: 0;
  width: 100%;
  height: 36px;
  background: none;
  font-family: inherit;
  appearance: none;
  line-height: 2;
  font-size: 1.25rem;

  ${inputBorderStyle};

  &:focus {
    outline: none;
  }

  &::after {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
  }
`

const SelectInput: AppFunctionComponent<InputHTMLAttributes<
  HTMLSelectElement
>> = (props) => {
  return (
    <Container>
      <Select {...props} />
    </Container>
  )
}

export default SelectInput
