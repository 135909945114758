import React, { FunctionComponent } from "react"
import styled from "styled-components"
import DotPattern from "../../../images/dot-pattern.svg"
import { backgroundColor } from "../../../theming/theme-getters"
import { QuoteComponent } from "../../../types/components.interface"
import LayoutBlock from "../../layout/layout-block.component"
import Quote from "../quote/quote.component"

const Container = styled.div`
  padding: 4rem 0 2rem;
  background: ${backgroundColor("dark")};
`

const InnerContent = styled(LayoutBlock)`
  position: relative;
  display: flex;
  flex-direction: column;

  @media (min-width: 48rem) {
    flex-direction: row;
  }
`

const EyeCandy = styled(DotPattern)`
  display: none;
  @media (min-width: 48rem) {
    display: block;
    position: absolute;
    top: 0.35rem;
    left: -2rem;
    transform: translateY(-50%);
    opacity: 0.3;
    z-index: 1;
  }
`

interface Props {
  readonly quotes: readonly QuoteComponent[]
}

const Quotes: FunctionComponent<Props> = ({ quotes }) => {
  const limitedQuotes = quotes.slice(0, 2)
  return (
    <Container>
      <InnerContent>
        {limitedQuotes.length >= 2 && <EyeCandy />}
        {limitedQuotes.map((quote, index) => {
          return (
            <Quote
              {...quote}
              withSibling={limitedQuotes.length >= 2}
              content={quote.text.text}
              key={index}
              data-testid="quote"
            />
          )
        })}
      </InnerContent>
    </Container>
  )
}

export default Quotes
