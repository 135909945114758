import { Link } from "gatsby"
import GatsbyImage from "gatsby-image"
import React, { FunctionComponent } from "react"
import styled, { css } from "styled-components"
import {
  backgroundColor,
  supportColor,
  textColor,
} from "../../../theming/theme-getters"
import { PostListingType } from "./post-listing-type.enum"
import PostsListingContent from "./posts-listing-content.interface"
import Tags from "../tags/tags.component"
import Time from "../time/time.component"
import AvatarIcon from "../../../images/avatar.svg"
import { fullAuthorPath } from "../../../functions/full-author-path"

const Wrapper = styled.div`
  padding: 3.75rem 1.25rem;
`

const Post = styled.div`
  max-width: 60.625rem;
  box-shadow: 0 10px 32px 0 rgba(42, 44, 50, 0.16);
  text-decoration: none;
  color: inherit;
  margin: 0 auto 3.75rem;
  border-radius: 5px 5px 0 0;
  overflow: hidden;

  @media (min-width: 65.625rem) {
    margin: 3.75rem auto 7.5rem;
    box-shadow: none;
    border-bottom: 1px solid ${textColor("primary")};

    &:last-child {
      margin: 3.75rem auto;
      border-bottom: 0;
    }
  }

  a {
    text-decoration: none;
  }
`
const Box = styled.div`
  padding: 2rem 1.25rem;

  @media (min-width: 65.625rem) {
    padding: 2rem 0;
  }
`

const Header = styled.header`
  display: flex;
  flex-direction: column;
`

const Date = styled(Time)`
  color: ${textColor("paragraph")};
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5;
  margin: 0;

  @media (min-width: 65.625rem) {
    font-size: 1.25rem;
  }
`

const Title = styled.h2`
  font-weight: 600;
  font-size: 1.25rem;
  color: ${textColor("primary")};

  @media (min-width: 65.625rem) {
    font-size: 2.625rem;
    margin: 1.875rem 0;
  }
`

const Content = styled.p`
  color: ${textColor("paragraph")};
  font-weight: 100;
  font-size: 1rem;

  @media (min-width: 65.625rem) {
    font-size: 1.25rem;
    margin-top: 0;
  }
`

const PreviewWrapper = styled.section`
  padding: 0 1.25rem 3.75rem;
  background: ${backgroundColor("footer")};

  @media (min-width: 65.625rem) {
    padding: 0 1.25rem 7.5rem;
  }
`

const PreviewBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 76.25rem;
  margin: 0 auto;
  flex-direction: row;
  justify-content: space-around;
`

const PreviewPost = styled(Link)`
  display: flex;
  flex-direction: column;
  max-width: 23.125rem;
  box-shadow: 0 10px 32px 0 rgba(42, 44, 50, 0.16);
  text-decoration: none;
  color: inherit;
  margin: 0 0.5rem 3.75rem;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  width: 100%;

  @media (min-width: 45rem) {
    min-width: 23.125rem;
  }
`

const PreviewPostBox = styled.div`
  padding: 2rem 1.25rem;
  display: flex;
  flex-direction: column;
  flex: 1;
`

const TitlePreview = styled.h2`
  font-weight: 600;
  font-size: 1.25rem;
  color: ${textColor("primary")};
  flex: 2;
`

const ContentPreview = styled.p`
  color: ${textColor("paragraph")};
  font-weight: 100;
  font-size: 1rem;
  flex: 1;
`

const DatePreview = styled(Time)`
  color: ${textColor("paragraph")};
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5;
  margin: 0;
`

const AuthorWrapper = styled.div`
  @media (min-width: 73.75rem) {
    display: flex;
    align-items: center;
    height: 3.125rem;
  }
`

const Author = styled(Link)`
  display: flex;
  text-decoration: none;
  color: ${textColor("paragraph")};
  align-items: center;
  margin-bottom: 1.25rem;

  @media (min-width: 73.75rem) {
    margin-bottom: 0;
  }
`

const AvatarStyle = css`
  border-radius: 50%;
  height: 2.5rem;
  width: 2.5rem;
  margin-right: 1rem;

  @media (min-width: 73.75rem) {
    height: 3.125rem;
    width: 3.125rem;
  }
`

const AuthorImage = styled(GatsbyImage)`
  ${AvatarStyle}
`

const AvatarPlaceholder = styled(AvatarIcon)`
  ${AvatarStyle}
`

const AuthorName = styled.p`
  font-size: ${16 / 16}rem;
  line-height: ${24 / 16};
  color: ${textColor("paragraph")};
  margin: 0;

  @media (min-width: 73.75rem) {
    font-size: ${20 / 16}rem;
    line-height: ${30 / 20};
  }
`

const Dot = styled.div`
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 50%;
  margin: 0 1rem;
  background-color: ${supportColor("dot")};
  display: none;

  @media (min-width: 73.75rem) {
    display: block;
  }
`

const StyledTags = styled(Tags)`
  margin-top: 3.75rem;
`

const PostsListing: FunctionComponent<PostsListingContent> = ({
  posts,
  type,
}) => {
  return (
    <>
      {type === PostListingType.Full ? (
        <Wrapper>
          <div>
            {posts.map(
              (
                {
                  featureImage,
                  title,
                  date,
                  path,
                  excerpt,
                  tags,
                  author,
                  fields,
                },
                index
              ) => {
                return (
                  <Post key={index}>
                    {featureImage && (
                      <Link to={path}>
                        <GatsbyImage
                          alt={featureImage.description}
                          fluid={featureImage.fluid}
                        />
                      </Link>
                    )}
                    <Box>
                      <Header>
                        <AuthorWrapper>
                          {author && author.image && (
                            <Author to={fullAuthorPath(author.authorPath)}>
                              {author.image?.fluid ? (
                                <AuthorImage
                                  alt={author.image.description}
                                  fluid={author.image.fluid}
                                />
                              ) : (
                                <AvatarPlaceholder />
                              )}
                              <AuthorName>{author.name}</AuthorName>
                            </Author>
                          )}
                          {author && <Dot />}
                          <Date date={date} />
                        </AuthorWrapper>
                        <Link to={path}>
                          <Title>{title}</Title>
                        </Link>
                      </Header>
                      {excerpt && <Content>{excerpt}</Content>}
                      {tags && <StyledTags fields={fields} />}
                    </Box>
                  </Post>
                )
              }
            )}
          </div>
        </Wrapper>
      ) : (
        <PreviewWrapper>
          <PreviewBox>
            {posts.map(
              ({ featureImage, title, date, path, excerpt }, index) => (
                <PreviewPost to={path} key={index}>
                  {featureImage && (
                    <GatsbyImage
                      alt={featureImage.description}
                      fluid={featureImage.fluid}
                    />
                  )}
                  <PreviewPostBox>
                    <DatePreview date={date} />
                    <TitlePreview>{title}</TitlePreview>
                    {excerpt && <ContentPreview>{excerpt}</ContentPreview>}
                  </PreviewPostBox>
                </PreviewPost>
              )
            )}
          </PreviewBox>
        </PreviewWrapper>
      )}
    </>
  )
}

export default PostsListing
