import React, { FunctionComponent } from "react"
import styled, { css } from "styled-components"
import { backgroundColor, textColor } from "../../../theming/theme-getters"
import StoreButtons from "../store-buttons/store-buttons.component"
import HeroContent from "./hero-content.interface"

const Container = styled.section<{ withBottomSpace: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  text-align: center;
  overflow: hidden;

  @media (min-width: 48rem) {
    padding-top: 3rem;
  }

  ${({ withBottomSpace }) =>
    withBottomSpace &&
    css`
      padding-bottom: 25vw;
      margin-bottom: -25vw;
      @media (min-width: 900px) {
        padding-bottom: 16rem;
        margin-bottom: -14rem;
      }
    `}
`

const Title = styled.h1`
  margin: 0 0 1.125rem;
  font-weight: 600;
  font-size: 2.625rem;

  @media (min-width: 48rem) {
    font-size: 3.375rem;
  }
`

const SubTitle = styled.h2`
  margin: 0 0 1rem;
  box-sizing: border-box;
  padding: 0 0.5rem;
  max-width: 100%;
  overflow: hidden;
  font-weight: 100;
  font-size: 2rem;

  @media (min-width: 48rem) {
    margin: 0 0 4rem;
  }
`

const VehiclesContainer = styled.div`
  overflow: hidden;
  vertical-align: bottom;
  margin-bottom: -0.3rem;
  box-sizing: border-box;
  padding: 0.3rem 0 0.8rem;
  height: 50px;
  max-width: 100%;
  display: inline-block;
  line-height: 1;
  border-radius: 2px;
  background: ${backgroundColor("light")};
  color: ${textColor("hover")};
`

const itemStyles = css`
  padding: 0 0.5rem;
  height: 50px;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const VehiclesList = styled.ul<{ framesCount: number; firstElement: string }>`
  margin: 0;
  padding-left: 0;
  list-style: none;
  animation: spinner ${({ framesCount }) => framesCount * 3}s infinite;
  box-sizing: border-box;
  width: 100%;

  &::after{
    content: "${({ firstElement }) => firstElement}";
    display: block;
    ${itemStyles};
  }

  @keyframes spinner {
    ${({ framesCount }) => {
      const elementHeight = 50
      const frameSize = Math.ceil(100 / framesCount)
      const halfFrame = Math.ceil(frameSize / 2)
      return Array.from({ length: framesCount })
        .map((_, index) => {
          const startFrame = index * frameSize
          return `
            ${startFrame}% {
              transform: translateY(-${elementHeight * index}px)
            }
            ${startFrame + halfFrame}% {
              transform: translateY(-${elementHeight * index}px)
            }
            ${Math.min(startFrame + 2 * halfFrame, 100)}% {
              transform: translateY(-${elementHeight * (index + 1)}px)
            }
          `
        })
        .join("")
    }}
  }
`

const VehicleItem = styled.li`
  ${itemStyles}
`

interface Props extends HeroContent {
  readonly withBottomSpace?: boolean
}

const Hero: FunctionComponent<Props> = ({
  title,
  subTitle,
  vehicles,
  withBottomSpace = false,
}) => {
  const [subtitlePart1, ...otherParts] = subTitle.split("$")
  return (
    <Container withBottomSpace={withBottomSpace}>
      <Title data-testid="title">{title}</Title>
      <SubTitle data-testid="subTitle">
        {subtitlePart1}
        <VehiclesContainer>
          <VehiclesList
            framesCount={vehicles.length}
            firstElement={vehicles[0]}
          >
            {vehicles.map((vehicle) => (
              <VehicleItem key={vehicle}>{vehicle}</VehicleItem>
            ))}
          </VehiclesList>
        </VehiclesContainer>
        {otherParts.join("$")}
      </SubTitle>
      <StoreButtons recognizeDevice />
    </Container>
  )
}

export default Hero
