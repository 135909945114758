import React from "react"
import AppFunctionComponent from "../../../types/app-function-component.interface"
import styled from "styled-components"
import EmptyBlogIcon from "../../../images/empty-blog.svg"
import { FormattedMessage } from "react-intl"

const Root = styled.div`
  padding: 5.625rem 0 7.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const Text = styled.p`
  font-size: ${20 / 16}rem;
  font-weight: 300;
  margin: 0 0 -7.5rem;
  padding-top: 3.125rem;
`

const EmptyListing: AppFunctionComponent = () => {
  return (
    <Root>
      <EmptyBlogIcon />
      <Text>
        <FormattedMessage id="listing.empty" />
      </Text>
    </Root>
  )
}

export default EmptyListing
