import React, { cloneElement, ReactElement, ReactNode } from "react"
import styled, { css } from "styled-components"
import { backgroundColor, textColor } from "../../../theming/theme-getters"
import AppFunctionComponent from "../../../types/app-function-component.interface"
import TextAreaInput from "../text-area-input/text-area-input.component"

const Container = styled.label<{ variant: "normal" | "stylish" }>`
  padding: 0 ${({ variant }) => variant === "stylish" && "2rem"};
  min-height: 3.75rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  ${({ variant = "normal" }) =>
    variant === "stylish" &&
    css`
      background: ${backgroundColor("light")};
      border-radius: 30px;
    `}
`

const LabelText = styled.span<{ active: boolean }>`
  display: block;
  color: ${textColor("paragraph")};
  line-height: 1;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  transition: top 150ms;
  pointer-events: none;

  ${TextAreaInput} + & {
    top: 15%;
  }

  ${({ active = false }) =>
    active &&
    css`
      top: 15%;

      ${TextAreaInput} + & {
        top: -5%;
      }
    `}
`

const getInputValue = (children?: ReactNode): string =>
  children && (children as ReactElement).props
    ? (children as ReactElement).props.value
    : ""

interface Props {
  readonly active?: boolean
  readonly text?: string
  readonly variant?: "normal" | "stylish"
}

const LabelElement: AppFunctionComponent<Props> = ({
  active = false,
  text,
  children,
  variant = "normal",
  className,
}) => {
  const withValue = Boolean(getInputValue(children))
  return (
    <Container variant={variant} className={className}>
      {children &&
        cloneElement(children as ReactElement, {
          variant: variant === "normal" ? "normal" : "plain",
        })}
      {text && <LabelText active={active || withValue}>{text}</LabelText>}
    </Container>
  )
}

export default LabelElement
