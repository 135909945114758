import { Link } from "gatsby"
import React, { FunctionComponent } from "react"
import styled, { css } from "styled-components"
import CookieIcon from "../../../images/cookie.svg"
import { backgroundColor, textColor } from "../../../theming/theme-getters"
import Button from "../../base/button/button.component"
import LayoutBlock from "../../layout/layout-block.component"
import { FormattedMessage } from "react-intl"

const Container = styled.aside<{ active: boolean }>`
  position: fixed;
  bottom: 0;
  width: 100%;
  background: ${backgroundColor("light")};
  opacity: 0;
  pointer-events: none;
  transition: opacity 150ms;

  ${({ active }) =>
    active &&
    css`
      opacity: 1;
      pointer-events: auto;
      z-index: 11;
    `}
`

const Content = styled(LayoutBlock)`
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media (min-width: 45rem) {
    flex-direction: row;
  }
`

const Info = styled.p`
  padding: 0 2rem;
  color: ${textColor("paragraph")};
  text-align: center;
`

const PolicyLink = styled(Link)`
  text-decoration: none;
  color: ${textColor("hover")};
`

const Cookie = styled(CookieIcon)`
  display: none;
  flex-shrink: 0;
  width: 60px;
  height: 60px;

  @media (min-width: 45rem) {
    display: block;
  }
`

interface Props {
  readonly active?: boolean
  readonly termsLink: string
  readonly onAccept?: () => void
}

const CookiesView: FunctionComponent<Props> = ({
  active = true,
  termsLink,
  onAccept = () => null,
}) => {
  return (
    <Container active={active}>
      <Content>
        <Cookie />
        <Info>
          <FormattedMessage id="cookies" />{" "}
          <PolicyLink to={termsLink}>
            <FormattedMessage id="cookies.link" />
          </PolicyLink>
        </Info>
        <Button onClick={onAccept}>
          <FormattedMessage id="cookies.button" />
        </Button>
      </Content>
    </Container>
  )
}

export default CookiesView
