import styled from "styled-components"
import { supportColor, textColor } from "../../../theming/theme-getters"

export enum ColorSquareVariant {
  CrazyOrange,
  ViolentViolet,
  PoliticalBlue,
}

const ColorSquare = styled.div<{ variant: ColorSquareVariant }>`
  border-radius: 6px;
  width: 280px;
  height: 280px;
  background: ${({ variant, theme }) => {
    switch (variant) {
      case ColorSquareVariant.PoliticalBlue:
        return supportColor("politicalBlue")({ theme })
      case ColorSquareVariant.CrazyOrange:
        return supportColor("crazyOrange")({ theme })
      case ColorSquareVariant.ViolentViolet:
        return supportColor("violentViolet")({ theme })
    }
  }};
  color: ${textColor("inverted")};
`

export default ColorSquare
