import React, { FunctionComponent } from "react"
import { FeatureComponent } from "../../../types/components.interface"
import { accumulateMicroFeature } from "../../resolver/components-accumulators"
import ResolverProperties from "../../resolver/resolver-properties.interface"
import { isMicroFeature } from "../../resolver/resolvers"
import MicroFeature from "../micro-feature/micro-feature.component"
import Feature from "./feature.component"
import ImageFeature from "./image-feature.component"
import isBlankFeature from "./is-blank-feature"

const FeatureResolver: FunctionComponent<ResolverProperties<
  FeatureComponent
>> = ({ component, components, index, path }) => {
  if (component.mobileImage) {
    if (isBlankFeature(component) && isMicroFeature(components[index + 1])) {
      const microFeaturesContent = (
        <MicroFeature
          inFullFeature={true}
          items={accumulateMicroFeature(components.slice(index + 1))}
        />
      )
      return (
        <ImageFeature
          {...component}
          content=""
          title=""
          contentSlot={microFeaturesContent}
        />
      )
    } else {
      return (
        <ImageFeature
          fancy={path === "/"}
          {...component}
          content={component.text.childMarkdownRemark.html}
        />
      )
    }
  } else if (component.specialContentId) {
    return (
      <Feature
        {...component}
        content={component.text.childMarkdownRemark.html}
      />
    )
  } else {
    return null
  }
}

export default FeatureResolver
