const subscribeToNewsletter = async (
  email: string
): Promise<{ error: string; ok: boolean }> => {
  try {
    const response = await fetch(
      "/.netlify/functions/subscribe-to-newsletter",
      {
        method: "post",
        body: JSON.stringify({ email }),
      }
    )
    const { status, message } = await response.json()
    if (status >= 400 && status < 500) {
      return { error: message, ok: false }
    } else if (status >= 500) {
      return {
        error: "Coś poszło nie tak. Spróbuj ponownie.",
        ok: false,
      }
    } else {
      return { error: "", ok: true }
    }
  } catch (e) {
    return {
      error: "Coś poszło nie tak. Spróbuj ponownie.",
      ok: false,
    }
  }
}

export default subscribeToNewsletter
