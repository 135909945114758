import GatsbyImage from "gatsby-image"
import React, { FunctionComponent } from "react"
import styled, { css } from "styled-components"
import { backgroundColor, textColor } from "../../../theming/theme-getters"
import LayoutBlock from "../../layout/layout-block.component"
import LogosShowcaseContent, {
  CompanyLogo,
} from "./logos-showcase-content.interface"

const Container = styled.div<{ inverted?: boolean }>`
  padding-bottom: 2.5rem;
  ${({ inverted = false }) =>
    inverted &&
    css`
      color: ${textColor("inverted")};
      background: ${backgroundColor("dark")};
    `}
`

const ScrollableLayoutBlock = styled(LayoutBlock)`
  overflow: auto;
`

const Logo = styled(GatsbyImage)`
  margin: 1rem 2rem;
  height: 4rem;
  width: 5rem;
  flex-shrink: 0;
`

const Row = styled.div`
  display: flex;
  margin: 0 auto;
  &::before,
  &::after {
    content: "";
  }
  &::before {
    margin-left: auto;
  }
  &::after {
    margin-right: auto;
  }
`

const Cell = styled.div``

const splitLogos = (
  logos: readonly CompanyLogo[]
): [CompanyLogo[], CompanyLogo[]] =>
  logos.reduce(
    (acc, item, index, logosList) => {
      if (index < logosList.length / 2) {
        acc[0].push(item)
      } else {
        acc[1].push(item)
      }
      return acc
    },
    [[], []] as [CompanyLogo[], CompanyLogo[]]
  )

interface Props extends Omit<LogosShowcaseContent, "name"> {
  readonly inverted?: boolean
}

const LogosShowcase: FunctionComponent<Props> = ({
  logos,
  inverted = false,
}) => {
  const rows = splitLogos(logos)
  return (
    <Container inverted={inverted}>
      <ScrollableLayoutBlock>
        {rows.map((row, index) => (
          <Row key={index}>
            {row.map(({ fluid, description }, itemIndex) => {
              return (
                <Cell key={itemIndex}>
                  <Logo fluid={fluid} alt={description} />
                </Cell>
              )
            })}
          </Row>
        ))}
      </ScrollableLayoutBlock>
    </Container>
  )
}

export default LogosShowcase
