import React, { FunctionComponent } from "react"
import { SocialLinksInterface } from "./social-links-interface"
import InstagramIcon from "../../../images/instagram.svg"
import TwitterIcon from "../../../images/twitter.svg"
import LinkedinIcon from "../../../images/linkedin.svg"
import EmailIcon from "../../../images/email.svg"
import FacebookIcon from "../../../images/facebook.svg"

const SocialLink: FunctionComponent<{
  link: string
  className?: string
  mail?: boolean
}> = ({ link, children, className, mail = false }) => {
  return (
    <a
      className={className}
      href={mail ? `mailto:${link}` : link}
      target="_blank"
      rel="noopener noreferrer nofollow"
    >
      {children}
    </a>
  )
}

const SocialLinks: FunctionComponent<
  SocialLinksInterface & {
    className?: string
  }
> = ({ socialLinks, className }) => {
  return (
    <>
      {socialLinks?.map((link, index) => {
        const isInsta = link.match(/instagram/)
        const isTwitter = link.match(/twitter/)
        const isLinkedin = link.match(/linkedin/)
        const isEmail = link.match(/@/)
        const isFacebook = link.match(/facebook/)
        if (isInsta) {
          return (
            <SocialLink className={className} key={index} link={link}>
              <InstagramIcon />
            </SocialLink>
          )
        } else if (isTwitter) {
          return (
            <SocialLink className={className} key={index} link={link}>
              <TwitterIcon />
            </SocialLink>
          )
        } else if (isLinkedin) {
          return (
            <SocialLink className={className} key={index} link={link}>
              <LinkedinIcon />
            </SocialLink>
          )
        } else if (isEmail) {
          return (
            <SocialLink
              className={className}
              key={index}
              mail={true}
              link={link}
            >
              <EmailIcon />
            </SocialLink>
          )
        } else if (isFacebook) {
          return (
            <SocialLink className={className} key={index} link={link}>
              <FacebookIcon />
            </SocialLink>
          )
        } else {
          return (
            <SocialLink className={className} key={index} link={link}>
              {/*  TODO need icon */}
            </SocialLink>
          )
        }
      })}
    </>
  )
}

export default SocialLinks
