export enum ContactInput {
  Name,
  Email,
  Phone,
  Topic,
  Message,
  Agreements,
}

export type TextInputs =
  | ContactInput.Name
  | ContactInput.Email
  | ContactInput.Phone
  | ContactInput.Topic
  | ContactInput.Message

interface TextInputChangeAction {
  input: TextInputs
  value: string
}

interface AgreementsInputChangeAction {
  input: ContactInput.Agreements
  index: number
}

export type InputChangeAction =
  | TextInputChangeAction
  | AgreementsInputChangeAction

export interface InputsValues {
  [ContactInput.Agreements]: readonly boolean[]
  [ContactInput.Name]: string
  [ContactInput.Email]: string
  [ContactInput.Phone]: string
  [ContactInput.Message]: string
  [ContactInput.Topic]: string
}
