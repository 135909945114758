import theme from "../../../theming/theme"
import NavigationItem from "../../../types/navigation-item.interface"

const letterWidth = 15
const menuItemMargin = 60
const widthOfOtherElementsInMenu = 100
const max = parseFloat(theme.layout.width) * 16

/**
 * Calculates the breakpoints for each menu item at which it should be still
 * visible and will fit in the remaining menu space. There might be fewer
 * breakpoints than menu items. Items without a corresponding breakpoints
 * won't be able to fit in the menu at any size, so they only rise
 * the overflown flag.
 */
const calculateBreakpointsForMenu = (items: NavigationItem[]) => {
  const [, breakpoints] = items.reduce(
    ([sum, breakpointsList], { navigationName }) => {
      const width = Math.ceil(
        navigationName.length * letterWidth + menuItemMargin
      )
      const newSum = sum + width
      if (newSum <= max) {
        breakpointsList.push(Math.round(newSum / 16))
      }
      return [newSum, breakpointsList] as [number, number[]]
    },
    [widthOfOtherElementsInMenu, []] as [number, number[]]
  )
  return {
    breakpoints,
    overflown: breakpoints.length < items.length,
  }
}

export default calculateBreakpointsForMenu
