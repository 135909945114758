import { graphql, useStaticQuery } from "gatsby"
import React, { createContext, FunctionComponent } from "react"
import SiteConfig from "../../../types/site-config.interface"

export const SiteConfigContext = createContext<SiteConfig>({
  anrLink: "",
  appStoreLink: "",
  googlePlayLink: "",
  twitterLink: "",
  youtubeLink: "",
  linkedinLink: "",
  instagramLink: "",
  facebookLink: "",
  privacyPolicyLink: {
    path: "",
  },
  blogReadMoreButton: "",
  euLink: "",
  euLogos: {
    fluid: {
      aspectRatio: 1,
      src: "",
      sizes: "",
      srcSet: "",
    },
  },
  euLogosMobile: {
    fluid: {
      aspectRatio: 1,
      src: "",
      sizes: "",
      srcSet: "",
    },
  },
})

const SiteConfigProvider: FunctionComponent = ({ children }) => {
  const { config } = useStaticQuery<{ config: SiteConfig }>(query)
  return (
    <SiteConfigContext.Provider value={config}>
      {children}
    </SiteConfigContext.Provider>
  )
}

export default SiteConfigProvider

const query = graphql`
  query SiteConfigQuery {
    config: contentfulSiteConfiguration {
      appStoreLink
      anrLink
      googlePlayLink
      twitterLink
      facebookLink
      youtubeLink
      linkedinLink
      instagramLink
      privacyPolicyLink {
        path
      }
      blogReadMoreButton
      euLink
      euLogos {
        fluid(maxWidth: 1300, quality: 95) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      euLogosMobile {
        fluid(maxWidth: 800, quality: 95) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
  }
`
