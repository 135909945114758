import React from "react"
import AppFunctionComponent from "../../../types/app-function-component.interface"
import styled from "styled-components"
import { textColor } from "../../../theming/theme-getters"
import { FormattedMessage } from "react-intl"
import { TagsHeaderContent } from "./tags-header.interface"

const Root = styled.div`
  display: none;

  @media (min-width: 35rem) {
    max-width: 60.625rem;
    padding: 2rem 1.25rem 0;
    margin: 0 auto -3.5rem;
    display: block;
  }

  @media (min-width: 65.625rem) {
    margin: 0 auto -6.5rem;
  }
`

const Art = styled.p`
  font-size: ${20 / 16}rem;
  color: ${textColor("paragraph")};
  line-height: ${30 / 20};
  margin-bottom: 0;
`

const TagName = styled.p`
  font-size: ${42 / 16}rem;
  font-weight: 600;
  margin: 0 0 5.625rem;
`

const TotalArticle = styled.p`
  font-size: ${20 / 16}rem;
  font-weight: 600;
`

const TagsHeader: AppFunctionComponent<TagsHeaderContent> = ({
  tagName,
  total,
}) => {
  return (
    <Root>
      <Art>
        <FormattedMessage id="tags.article" />
      </Art>
      <TagName>{tagName}</TagName>
      <TotalArticle>
        <FormattedMessage id="article" values={{ num: total }} />
      </TotalArticle>
    </Root>
  )
}

export default TagsHeader
