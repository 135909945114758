import Component, {
  ButtonComponent,
  CallToActionComponent,
  CardComponent,
  ComponentType,
  ContactFormComponent,
  DocumentComponent,
  EmptyListingComponent,
  FeatureComponent,
  HeadlineComponent,
  HeroComponent,
  ImageComponent,
  LogosShowcaseComponent,
  MapComponent,
  MicroFeatureComponent,
  NewsletterComponent,
  NormalCardComponent,
  OpinionComponent,
  PaginationComponent,
  PasswordResetFormComponent,
  PasswordResetIcon,
  PostAuthorHeader,
  PostComponent,
  PostsListingComponent,
  QuoteComponent,
  SmallCardComponent,
  TagsHeaderComponent,
  UniqueComponent,
  VideoComponent,
} from "../../types/components.interface"

const is = (component: Component, wantedType: ComponentType) => {
  if (!component.internal) {
    return false
  }
  return component.internal.type === wantedType
}

export const isHero = (component: Component): component is HeroComponent =>
  is(component, "ContentfulHero")

export const isHeadline = (
  component: Component
): component is HeadlineComponent => is(component, "ContentfulHeadline")

export const isFeature = (
  component: Component
): component is FeatureComponent => is(component, "ContentfulFeature")

export const isOpinion = (
  component: Component
): component is OpinionComponent => is(component, "ContentfulOpinion")

export const isLogosShowcase = (
  component: Component
): component is LogosShowcaseComponent =>
  is(component, "ContentfulLogosShowcase")

export const isQuote = (component: Component): component is QuoteComponent =>
  is(component, "ContentfulQuote")

export const isCard = (component: Component): component is CardComponent =>
  is(component, "ContentfulCard")

export const isSmallCard = (
  component: Component
): component is SmallCardComponent =>
  isCard(component) && (component as CardComponent).type === "small"

export const isNormalCard = (
  component: Component
): component is NormalCardComponent =>
  isCard(component) && (component as CardComponent).type === "normal"

export const isMicroFeature = (
  component: Component
): component is MicroFeatureComponent => is(component, "ContentfulMicroFeature")

export const isContactForm = (
  component: Component
): component is ContactFormComponent => is(component, "ContentfulContactForm")

export const isPost = (component: Component): component is PostComponent =>
  is(component, "ContentfulPost")

export const isPostsListing = (
  component: Component
): component is PostsListingComponent => is(component, "ContentfulPostsListing")

export const isCallToAction = (
  component: Component
): component is CallToActionComponent => is(component, "ContentfulCallToAction")

export const isHomePageMap = (
  component: Component
): component is MapComponent => is(component, "AppHomePageMap")

export const isTagHeader = (
  component: Component
): component is TagsHeaderComponent => is(component, "AppTagsHeader")

export const isDocument = (
  component: Component
): component is DocumentComponent => is(component, "ContentfulDocument")

export const isVideo = (component: Component): component is VideoComponent =>
  is(component, "ContentfulVideo")

export const isPagination = (
  component: Component
): component is PaginationComponent => is(component, "AppPagination")

export const isNewsletter = (
  component: Component
): component is NewsletterComponent => is(component, "ContentfulNewsletter")

export const isImage = (component: Component): component is ImageComponent =>
  is(component, "ContentfulImage")

export const isUniqueComponent = (
  component: Component
): component is UniqueComponent => is(component, "ContentfulUniqueComponent")

export const isButton = (component: Component): component is ButtonComponent =>
  is(component, "AppButton")

export const isPasswordResetIcon = (
  component: Component
): component is PasswordResetIcon => is(component, "AppPasswordResetIcon")

export const isPasswordResetForm = (
  component: Component
): component is PasswordResetFormComponent =>
  is(component, "AppPasswordResetForm")

export const isPostAuthorHeader = (
  component: Component
): component is PostAuthorHeader => is(component, "AppPostAuthorHeader")

export const isEmptyListing = (
  component: Component
): component is EmptyListingComponent => is(component, "AppEmptyListing")
