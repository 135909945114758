import { graphql, useStaticQuery } from "gatsby"
import GatsbyImage, { FluidObject } from "gatsby-image"
import React, { FunctionComponent } from "react"
import styled from "styled-components"
import LayoutBlock from "../../layout/layout-block.component"

const MapBlock = styled(LayoutBlock)`
  display: flex;
  justify-content: center;
`

const MapImage = styled(GatsbyImage)`
  width: 1340px;
  max-width: calc(100vw - 2rem);
  flex-shrink: 0;
`

export const MapView: FunctionComponent<{ image: FluidObject }> = ({
  image,
}) => {
  return (
    <MapBlock>
      <MapImage fluid={image} />
    </MapBlock>
  )
}

const Map: FunctionComponent = () => {
  const { image } = useStaticQuery(query)
  return <MapView image={image.fluid} />
}

export default Map

// FIXME: reliance on the existence of an image in Contentful
// makes this component fragile.
const query = graphql`
  {
    image: contentfulAsset(title: { eq: "Home Page Map" }) {
      fluid(maxWidth: 1340, quality: 100) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
  }
`
