import Component from "../../types/components.interface"
import {
  isMicroFeature,
  isNormalCard,
  isOpinion,
  isQuote,
  isSmallCard,
} from "./resolvers"

/**
 * Returns a function accumulating neighbouring components of the same
 * kind in its own list. Accumulator requires a component recognizing
 * predicate.
 *
 * @example
 * const someComponents = accumulateComponents(isSomeComponent)
 *
 * @param predicate Guard function recognizing components.
 */
const accumulateComponents = <T extends Component>(
  predicate: (component: Component) => component is T
) => {
  return function accumulator(components: readonly Component[]): T[] {
    const component = components[0] as T
    if (component && predicate(component)) {
      return [component, ...accumulator(components.slice(1))]
    } else {
      return []
    }
  }
}

export const accumulateOpinions = accumulateComponents(isOpinion)
export const accumulateQuotes = accumulateComponents(isQuote)
export const accumulateSmallCards = accumulateComponents(isSmallCard)
export const accumulateNormalCards = accumulateComponents(isNormalCard)
export const accumulateMicroFeature = accumulateComponents(isMicroFeature)
