import React, {
  ChangeEvent,
  FormEvent,
  FunctionComponent,
  useState,
} from "react"
import styled from "styled-components"
import text from "../../../functions/text"
import { textColor } from "../../../theming/theme-getters"
import PendableButton from "../../base/button/pendable-button.component"
import Label from "../../base/label/label.component"
import TextInput from "../../base/text-input/text-input.component"
import LayoutBlock from "../../layout/layout-block.component"
import EyeIcon from "../../../images/eye-icon.svg"
import EyeCrossedIcon from "../../../images/eye-icon-cross.svg"

const Form = styled.form`
  max-width: 350px;
  margin: 0 auto;
`

const Inputs = styled.div`
  margin: 0;
  border: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  position: relative;

  > * {
    margin-bottom: 2rem;
  }

  @media (min-width: 48rem) {
    margin-bottom: 2rem;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto auto;

    > * {
      margin-bottom: 0;
    }

    p {
      grid-row: 2/2;
    }
  }
  ${TextInput} {
    width: 100%;
  }
`

const ErrorMessage = styled.p`
  padding-top: 10px;
  color: ${textColor("error")};
`

const SubmitButton = styled(PendableButton)`
  width: 100%;
  display: block;
  margin-bottom: 0;
  @media (min-width: 43.75rem) {
    display: initial;
    width: initial;
    margin-top: 2rem;
  }
`

const PasswordButton = styled.div`
  position: absolute;
  right: 0;
  top: 0.875rem;
  cursor: pointer;
  height: 24px;
  width: 24px;
`

export interface Props {
  readonly onSubmit?: () => void
  readonly onInputChange?: (value: string) => void
  readonly pending?: boolean
  readonly value?: string
  readonly done?: boolean
  readonly errorMessage?: string
  readonly sendButtonText?: string
  readonly nameLabel?: string
}

const ResetPasswordFormUI: FunctionComponent<Props> = ({
  onSubmit = () => null,
  onInputChange = () => null,
  pending = false,
  done = false,
  errorMessage,
  sendButtonText,
  value,
  nameLabel,
}) => {
  const onFormSubmit = (event: FormEvent) => {
    event.preventDefault()
    if (!pending) {
      onSubmit()
    }
  }
  const onInputValueChange = ({
    target: { value: newValue },
  }: ChangeEvent<HTMLInputElement>) => {
    onInputChange(newValue)
  }

  const [showPassword, setShowPassword] = useState(false)
  const handlePasswordShow = () => setShowPassword(!showPassword)

  const inputDisabled = pending || done
  return (
    <LayoutBlock>
      <Form onSubmit={onFormSubmit}>
        <Inputs>
          <Label text={nameLabel}>
            <TextInput
              disabled={inputDisabled}
              type={showPassword ? "text" : "password"}
              onChange={onInputValueChange}
              value={value}
              required
              name={"name"}
              minLength={8}
              maxLength={20}
            />
          </Label>
          <PasswordButton
            role="button"
            tabIndex={0}
            onClick={handlePasswordShow}
            onKeyDown={handlePasswordShow}
          >
            {showPassword ? <EyeCrossedIcon /> : <EyeIcon />}
          </PasswordButton>
          <SubmitButton pending={pending} disabled={done}>
            {sendButtonText}
          </SubmitButton>
          {errorMessage && <ErrorMessage>{text(errorMessage)}</ErrorMessage>}
        </Inputs>
      </Form>
    </LayoutBlock>
  )
}

export default ResetPasswordFormUI
