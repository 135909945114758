import { graphql, useStaticQuery } from "gatsby"
import GatsbyImage, { FixedObject } from "gatsby-image"
import React, { FunctionComponent } from "react"
import styled, { css } from "styled-components"
import text from "../../../functions/text"
import { backgroundColor } from "../../../theming/theme-getters"
import LayoutBlock from "../../layout/layout-block.component"
import CtaLink from "../cta-link/cta-link.component"
import StoreButtons from "../store-buttons/store-buttons.component"
import CallToActionContent, {
  CallToActionType,
} from "./call-to-action-content.interface"
import InfoSection from "./info-section.component"

const Container = styled.div<{ main: boolean }>`
  position: relative;
  overflow: hidden;

  @media (min-width: 43.75rem) {
    margin-top: ${({ main }) => (main ? `0` : `-28.75rem`)};
  }

  ${({ main }) =>
    main &&
    css`
      &::before {
        content: "";
        display: block;
        height: 12rem;
        background: ${backgroundColor("dark")};
      }
    `}
`

const Content = styled(LayoutBlock)`
  position: relative;
  padding-top: 10rem;
  padding-bottom: 4rem;

  @media (min-width: 43.75rem) {
    padding-bottom: 10rem;
    display: flex;
    justify-content: flex-start;
    margin: 0 auto;
  }
`

const Block = styled.div`
  @media (min-width: 43.75rem) {
    width: 50%;
  }
`

const Copy = styled(Block)`
  margin: 3rem auto 0;
  max-width: 30rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (min-width: 43.75rem) {
    margin: 40rem 0 0;
    justify-content: flex-end;
    align-items: flex-end;
  }
`

const Aside = styled(Block)``

const Prompt = styled.p`
  margin: 0.5rem 0.5rem 3.75rem;
  text-align: center;
  font-size: 2.625rem;
  font-weight: 600;

  @media (min-width: 43.75rem) {
    text-align: right;
  }
`

const ImageWrapper = styled.div<{ floating: boolean }>`
  display: none;
  @media (min-width: 43.75rem) {
    display: block;
    z-index: -1;
    position: absolute;
    bottom: ${({ floating }) => (floating ? `0` : `-10%`)};
    right: ${({ floating }) => (floating ? `0` : `9%`)};
  }
`

const BackgroundImage = styled(GatsbyImage)`
  width: 100%;
  background-position: bottom right;
  margin: 0 auto;
`

const CallToActionLink = styled(CtaLink)<{ floating: boolean }>`
  position: ${({ floating }) => (floating ? `absolute` : `relative`)};
  top: 0;
  ${({ floating }) =>
    floating
      ? `right: 50%; transform: translate(50%, -50%);`
      : `left: 0; margin 0 auto;`};
  @media (min-width: 43.75rem) {
    ${({ floating }) =>
      floating ? "right: 20%" : `left: 12%; top: 60%; margin: 0`};
    transform: translateY(-50%);
  }
`

export const CallToActionView: FunctionComponent<
  CallToActionContent & {
    image: FixedObject
  }
> = ({ buttonTarget, buttonText, callToAction, type, image, features }) => {
  const withLink = Boolean(buttonText && buttonTarget)
  const main = type === CallToActionType.Main
  return (
    <Container main={main}>
      <ImageWrapper floating={main}>
        <BackgroundImage fixed={image} alt="" />
      </ImageWrapper>
      <Content>
        <Aside>
          {withLink && (
            <CallToActionLink
              to={buttonTarget}
              content={text(buttonText)}
              floating={main}
            />
          )}
          {main && features && features.length && (
            <InfoSection features={features} />
          )}
        </Aside>
        <Copy>
          <Prompt>{text(callToAction)}</Prompt>
          <StoreButtons />
        </Copy>
      </Content>
    </Container>
  )
}

const CallToAction: FunctionComponent<CallToActionContent> = (props) => {
  const {
    file: {
      childImageSharp: { fixed },
    },
  } = useStaticQuery(query)
  return <CallToActionView {...props} image={fixed} />
}

export default CallToAction

const query = graphql`
  query MapImage {
    file(name: { eq: "map-bg" }) {
      childImageSharp {
        fixed(width: 1622, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
  }
`
