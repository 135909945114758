import React, { useContext, useEffect } from "react"
import styled, { css } from "styled-components"
import AppStore from "../../../images/appstore.svg"
import PlayStore from "../../../images/playstore.svg"
import AppFunctionComponent from "../../../types/app-function-component.interface"
import { SiteConfigContext } from "../../context/site-config/site-config.context"
import { FormattedMessage } from "react-intl"

export enum Store {
  AppStore,
  Playstore,
}

interface Props {
  recognizeDevice?: boolean
}

const Container = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 27rem) {
    flex-direction: row;
  }
`

const StoreButton = styled.a<{ store: Store }>`
  font-size: 0;
  margin: 0.5rem;

  html.device-ios & {
    ${({ store }) => {
      if (store === Store.Playstore) {
        return css`
          display: none;
        `
      } else {
        return ""
      }
    }}
  }
  html.device-android & {
    ${({ store }) => {
      if (store === Store.AppStore) {
        return css`
          display: none;
        `
      } else {
        return ""
      }
    }}
  }
`

const StoreButtons: AppFunctionComponent<Props> = ({
  className,
  recognizeDevice,
}) => {
  useEffect(() => {
    function isClient() {
      return typeof window !== "undefined"
    }

    function setDocumentClass(classname: string) {
      if (isClient() && recognizeDevice) {
        document.documentElement.classList.add(classname)
      }
    }

    if (isClient()) {
      if (navigator.userAgent.match(/(iPad|iPhone|iPod)/i)) {
        setDocumentClass("device-ios")
      } else if (navigator.userAgent.match(/android/i)) {
        setDocumentClass("device-android")
      } else {
        setDocumentClass("device-unknown")
      }
    }
  }, [])
  const config = useContext(SiteConfigContext)
  return (
    <Container className={className}>
      <StoreButton
        data-testid="appstore"
        store={Store.AppStore}
        href={config.appStoreLink}
      >
        <FormattedMessage id="download.appstore" />
        <AppStore />
      </StoreButton>
      <StoreButton
        data-testid="playstore"
        store={Store.Playstore}
        href={config.googlePlayLink}
      >
        <FormattedMessage id="download.playstore" />
        <PlayStore />
      </StoreButton>
    </Container>
  )
}

export default StoreButtons
