import GatsbyImage from "gatsby-image"
import React, { FunctionComponent, ReactElement, ReactNode } from "react"
import rehypeReact from "rehype-react"
import styled from "styled-components"
import { textColor } from "../../../theming/theme-getters"
import Quote from "../../common/quote/quote.component"
import PostContent from "./post-content.interface"
import { Link } from "gatsby"
import { YTWrapper } from "../../common/video/video.component"
import Time from "../time/time.component"
import Tags from "../tags/tags.component"
import PostAuthor, {
  Avatar,
  AvatarPlaceholder,
  Bio,
  Name,
  AuthorContainer,
} from "../post-author/post-author.component"
import { StyledLink } from "../../global-style.component"

const Container = styled.div`
  max-width: 73.75rem;
  margin: 0 auto;
`

const Article = styled.article`
  padding: 3.75rem 2rem;
`

const Image = styled(GatsbyImage)`
  margin: 0 -2rem;
`

const Header = styled.header`
  max-width: 49rem;
  margin: 0 auto;
  position: relative;

  @media (min-width: 73.75rem) {
    margin: 7.5rem auto 3.75rem;
  }
`

const Title = styled.h1`
  font-weight: 600;
  line-height: 1.5;
  font-size: 1.25rem;

  @media (min-width: 73.75rem) {
    font-size: 2.625rem;
  }
`

const Box = styled.div`
  @media (min-width: 73.75rem) {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-start;
  }
`

const ContentWrapper = styled.div`
  @media (min-width: 73.75rem) {
    position: relative;
    left: -12px;
    width: 100%;
  }
`

const SocialWrapper = styled.div`
  max-width: 49rem;
  margin: 0 auto;
  @media (min-width: 73.75rem) {
    position: sticky;
    top: 4rem;
    margin: 7.5rem 0 0 -2rem;
    max-width: min-content;
  }
`

const Content = styled.div`
  max-width: 49rem;
  margin: 0 auto;
  color: ${textColor("paragraph")};
  font-weight: 300;

  @media (min-width: 73.75rem) {
    font-size: 1.25rem;
  }

  h2 {
    font-weight: 600;
    line-height: 1.5;
    font-size: 1.25rem;
    color: ${textColor("primary")};
    margin: 2rem 0;

    @media (min-width: 73.75rem) {
      font-size: 2rem;
    }
  }

  strong {
    color: ${textColor("primary")};
    font-weight: 600;
  }

  blockquote {
    margin: 5.5rem 0 3.75rem;

    @media (min-width: 73.75rem) {
      margin: 5.5rem -9rem 3.75rem;
      padding: 5.625rem 8.625rem 3.75rem;
    }
  }

  a {
    ${StyledLink}
  }
`
const StyledTags = styled(Tags)`
  max-width: 49rem;
  margin: 3.75rem auto;
`

const StyledAuthor = styled(PostAuthor)`
  ${AuthorContainer} {
    max-width: 48.75rem;
  }

  ${AvatarPlaceholder}, ${Avatar} {
    width: 5rem;
    height: 5rem;
    @media (min-width: 73.75rem) {
      width: 4.5rem;
      height: 4.5rem;
    }
  }

  ${Name} {
    font-size: ${32 / 16}rem;

    @media (min-width: 73.75rem) {
      font-size: ${20 / 16}rem;
    }
  }

  ${Bio} {
    font-size: ${16 / 16}rem;
  }
`

const getCite = (item: ReactElement): ReactElement | null => {
  if (
    item &&
    item.props &&
    item.props.children &&
    Array.isArray(item.props.children)
  ) {
    const [cite] = item.props.children.filter(
      (el: ReactElement) => el.type === "cite"
    )

    return cite || null
  } else {
    return null
  }
}

const QuoteMapper = ({ children }: { children: ReactElement[] }) => {
  const content = children.filter((item) => !Boolean(getCite(item)))
  const author = children.reduce((acc, item) => {
    const cite = getCite(item)
    if (cite) {
      return cite.props.children[0]
    } else {
      return acc
    }
  }, "")
  return <Quote content={content} author={author} />
}

const VideoMapper = ({
  src,
  allow,
  allowFullScreen,
  frameBorder,
}: {
  src: string
  allow: string
  frameBorder: string
  allowFullScreen: boolean
}) => {
  return (
    <YTWrapper>
      <iframe
        src={src}
        allow={allow}
        allowFullScreen={allowFullScreen}
        frameBorder={frameBorder}
      />
    </YTWrapper>
  )
}

export const LinkMapper = ({
  href,
  children,
  ...rest
}: {
  href: string
  children: string[]
}) => {
  const siteUrlEnv = process.env.GATSBY_SITE_URL || "https://vooom.pl/"
  const siteUrl = siteUrlEnv.endsWith("/") ? siteUrlEnv : `${siteUrlEnv}/`
  const hrefLink = href.endsWith("/") ? href : `${href}/`
  const localLink = hrefLink.replace(siteUrl, "/")

  const innerLink = href.startsWith("/") || hrefLink.startsWith(siteUrl)
  if (innerLink) {
    return (
      <Link to={hrefLink.startsWith(siteUrl) ? localLink : hrefLink}>
        {children}
      </Link>
    )
  } else {
    return (
      <a
        rel="noreferrer noopener nofollow"
        target="_blank"
        href={href}
        {...rest}
      >
        {children}
      </a>
    )
  }
}

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: {
    blockquote: QuoteMapper,
    iframe: VideoMapper,
    a: LinkMapper,
  },
}).Compiler

const Post: FunctionComponent<
  PostContent & {
    socialSharesElement: ReactNode
  }
> = ({
  content,
  featureImage,
  title,
  date,
  socialSharesElement,
  tags,
  author,
  fields,
}) => {
  return (
    <Container>
      <Article>
        {featureImage && (
          <Image fluid={featureImage.fluid} alt={featureImage.description} />
        )}
        <Box>
          <ContentWrapper>
            <Header>
              <Time date={date} />
              <Title>{title}</Title>
            </Header>
            <Content>{renderAst(content)}</Content>
            {tags && <StyledTags fields={fields} />}
          </ContentWrapper>
          <SocialWrapper>{socialSharesElement}</SocialWrapper>
        </Box>
      </Article>
      {author && <StyledAuthor {...author} />}
    </Container>
  )
}

export default Post
