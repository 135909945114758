import Image from "gatsby-image"
import React, { FunctionComponent } from "react"
import { ImageFeatureContent } from "./feature-content.interface"
import Feature from "./feature.component"

const ImageFeature: FunctionComponent<ImageFeatureContent> = ({
  mobileImage,
  tabletImage,
  desktopImage,
  ...props
}) => {
  const sources = [
    mobileImage.fluid,
    { ...tabletImage.fluid, media: `(min-width: 450px)` },
    { ...desktopImage.fluid, media: `(min-width: 750px)` },
  ]
  return (
    <Feature {...props}>
      <Image fluid={sources} />
    </Feature>
  )
}

export default ImageFeature
