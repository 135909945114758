import React from "react"
import styled, { css, keyframes } from "styled-components"
import LoaderIcon from "../../../images/loading.svg"
import AppFunctionComponent from "../../../types/app-function-component.interface"
import Button from "./button.component"

const spinningAnimation = keyframes`
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
`

const Spinner = styled(({ active, ...rest }) => <LoaderIcon {...rest} />)<{
  active: boolean
}>`
  position: absolute;
  top: 50%;
  left: 50%;

  animation: ${spinningAnimation} 2s linear infinite;
  opacity: 0;
  transition: opacity 250ms;
  transform: translate(-50%, -50%);

  ${({ active }) =>
    active &&
    css`
      transition: opacity 250ms 250ms;
      opacity: 1;
    `}
`

const ButtonWithAnimation = styled(Button)<{ inactive?: boolean }>`
  position: relative;
  transition: color 250ms 250ms;
  ${({ inactive }) =>
    inactive &&
    css`
      color: transparent;
      transition: color 250ms;
    `}
`

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  readonly pending?: boolean
}

const PendableButton: AppFunctionComponent<Props> = ({
  children,
  pending = false,
  ...props
}) => {
  return (
    <ButtonWithAnimation {...props} inactive={pending}>
      <Spinner active={pending} />
      {children}
    </ButtonWithAnimation>
  )
}

export default PendableButton
