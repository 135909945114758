import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import ArrowIcon from "../../../images/cta-point-arrow.svg"
import { supportColor, textColor } from "../../../theming/theme-getters"
import AppFunctionComponent from "../../../types/app-function-component.interface"

const BlinkingLink = styled(Link)`
  width: 17.375rem;
  height: 17.375rem;
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  background: ${supportColor("actionable")};
  color: ${textColor("inverted")};
  text-decoration: none;
  text-align: center;
  font-size: 1.25rem;
  position: relative;

  &::before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: inherit;
    border-radius: inherit;
    transition: opacity 0.3s, transform 0.3s;
    animation: pulse 2s cubic-bezier(0.24, 0, 0.38, 1) infinite;
    z-index: -1;
  }

  &:hover {
    background: ${supportColor("hoveredActionable")};

    &::before {
      animation-play-state: paused;
    }
  }

  &:active {
    background: ${supportColor("activeActionable")};
  }

  @keyframes pulse {
    0% {
      opacity: 1;
      transform: scale(1);
    }
    50% {
      opacity: 0;
      transform: scale(1.3);
    }
    100% {
      opacity: 0;
      transform: scale(1.3);
    }
  }
`

const Arrow = styled(ArrowIcon)`
  margin-bottom: 2.5rem;
`

const Content = styled.span`
  margin: 1rem 0 2.5rem;
  padding: 0 1rem 0;
  display: flex;
`

interface Props {
  readonly content: string
  readonly to: string
}

const CtaLink: AppFunctionComponent<Props> = ({ content, to, className }) => {
  return (
    <BlinkingLink to={to} className={className}>
      <Content>{content}</Content>
      <Arrow />
    </BlinkingLink>
  )
}

export default CtaLink
