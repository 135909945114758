import React, { FunctionComponent } from "react"
import styled from "styled-components"
import VideoContent from "./video.interface"

const Root = styled.section`
  padding: 1.25rem;
  max-width: 73.75rem;
  margin: 0 auto;

  @media (min-width: 48rem) {
    padding: 3.75rem 5rem;
  }
`

export const YTWrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const Uploaded = styled.video`
  width: 100%;
  height: auto;
`

const Video: FunctionComponent<VideoContent> = ({ embed, media }) => (
  <Root>
    {embed && <YTWrapper dangerouslySetInnerHTML={{ __html: embed.embed }} />}
    {media && <Uploaded src={media.file.url} controls />}
  </Root>
)

export default Video
