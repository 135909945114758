import React, { FunctionComponent } from "react"
import styled from "styled-components"
import { addUniqueComponentsForPath } from "../../../templates/unique-components"
import theme from "../../../theming/theme"
import { backgroundColor } from "../../../theming/theme-getters"
import Component from "../../../types/components.interface"
import { PostListingType } from "../../blog/posts-listing/post-listing-type.enum"
import LandingLayout from "../../layout/landing-layout.component"
import Resolver from "../../resolver/resolver.component"
import { isHero, isNewsletter, isPostsListing } from "../../resolver/resolvers"

const Title = styled.h1`
  margin: 0;
  padding-bottom: 2rem;
  font-weight: 600;
  font-size: 3.375rem;
`

interface Props {
  readonly path: string
  readonly title?: string
  readonly seoTitle?: string
  readonly description?: string
  readonly components: readonly Component[]
  readonly seoImage?: string
}

const resolveFooterAdditionalBackground = (
  lastComponent: Component
): string | undefined => {
  if (!lastComponent) {
    return undefined
  }
  if (isNewsletter(lastComponent)) {
    return backgroundColor("dark")({ theme })
  } else if (
    isPostsListing(lastComponent) &&
    lastComponent.type === PostListingType.Preview
  ) {
    return backgroundColor("footer")({ theme })
  }
  return undefined
}

const Page: FunctionComponent<Props> = ({
  path,
  title,
  seoTitle,
  description,
  components,
  seoImage,
}) => {
  const withHero = Boolean(
    components && components.length && isHero(components[0])
  )
  const inHeader = withHero ? (
    <Resolver components={[components[0]]} path={path} />
  ) : (
    <Title data-testid="page-title">{title}</Title>
  )
  const restComponents = withHero ? components.slice(1) : components || []
  const footerAdditionalBackground = resolveFooterAdditionalBackground(
    components[components.length - 1]
  )

  return (
    <LandingLayout
      footerAdditionalBackground={footerAdditionalBackground}
      headerSubSection={inHeader}
      title={title}
      seoTitle={seoTitle}
      withSeoTitleTemplate={path !== "/"}
      description={description}
      seoImage={seoImage}
    >
      <Resolver
        components={addUniqueComponentsForPath(restComponents, path)}
        path={path}
      />
    </LandingLayout>
  )
}

export default Page
