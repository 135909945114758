import React, { FunctionComponent } from "react"
import Component, {
  FeatureComponent,
  PostsListingComponent,
} from "../../types/components.interface"
import Button from "../base/button/button.component"
import Post from "../blog/post/post.component"
import { PostListingType } from "../blog/posts-listing/post-listing-type.enum"
import PostsListing from "../blog/posts-listing/posts-listing.component"
import CallToAction from "../common/call-to-action/call-to-action.component"
import Cards from "../common/cards/cards.component"
import Document from "../common/document/document.component"
import FeatureResolver from "../common/feature/feature-resolver.component"
import isBlankFeature from "../common/feature/is-blank-feature"
import Headline from "../common/headline/headline.component"
import Hero from "../common/hero/hero.component"
import Image from "../common/image/image.component"
import LogosShowcase from "../common/logos-showcase/logos-showcase.component"
import MicroFeature from "../common/micro-feature/micro-feature.component"
import Newsletter from "../common/newsletter/newsletter.component"
import Pagination from "../common/pagination/pagination.component"
import Quotes from "../common/quotes/quotes.component"
import SocialShare from "../common/social-sharing/social-sharing.component"
import UniqueComponentResolver from "../common/unique-component/unique-component.component"
import Video from "../common/video/video.component"
import ContactForm from "../contact/contact-form/contact-form.component"
import Map from "../home/map/map.component"
import OpinionsSlider from "../home/opinions-slider/opinions-slider.component"
import PasswordResetForm from "../reset-password/password-reset-form/password-reset-form.component"
import PasswordResetIcon from "../reset-password/password-reset-icon/password-reset-icon.component"
import {
  accumulateMicroFeature,
  accumulateNormalCards,
  accumulateOpinions,
  accumulateQuotes,
  accumulateSmallCards,
} from "./components-accumulators"
import {
  isButton,
  isCallToAction,
  isContactForm,
  isDocument,
  isEmptyListing,
  isFeature,
  isHeadline,
  isHero,
  isHomePageMap,
  isImage,
  isLogosShowcase,
  isMicroFeature,
  isNewsletter,
  isNormalCard,
  isOpinion,
  isPagination,
  isPasswordResetForm,
  isPasswordResetIcon,
  isPost,
  isPostAuthorHeader,
  isPostsListing,
  isQuote,
  isSmallCard,
  isTagHeader,
  isUniqueComponent,
  isVideo,
} from "./resolvers"
import SmallCardsContainer from "../common/small-cards/small-cards-container.component"
import TagsHeader from "../blog/tags-header/tags-header.component"
import PostAuthor from "../blog/post-author/post-author.component"
import EmptyListing from "../blog/empty-listing/empty-listing.component"

interface Props {
  readonly components?: readonly Component[]
  readonly path?: string
}

const onlyProperComponents = (component: Component) =>
  Boolean(component.internal)

// TODO: resolver now does so many things: styling, content resolving and other.
// It would be a good idea to rethink its responsibilities and maybe introduce
// a components specific resolvers that will do such things.
const Resolver: FunctionComponent<Props> = ({
  components = [],
  path = "/",
}) => {
  return (
    <>
      {components.filter(onlyProperComponents).map((component, index) => {
        if (isHero(component)) {
          return (
            // TODO: Bottom space should be resolved based on components alone.
            // For example, there might be a ruleset for each type of component
            // that applies specific props to them. On the other hand, features
            // components need special treatment only on the home page.
            <Hero {...component} key={index} withBottomSpace={path === "/"} />
          )
        } else if (isHeadline(component)) {
          // TODO: need a better way of deciding styles.
          const previousIsOpinion =
            components[index - 1] && isOpinion(components[index - 1])
          const nextIsPreviewListing =
            components[index + 1] &&
            isPostsListing(components[index + 1]) &&
            (components[index + 1] as PostsListingComponent).type ===
              PostListingType.Preview
          return (
            <Headline
              {...component}
              key={index}
              inverted={previousIsOpinion}
              shady={nextIsPreviewListing}
            />
          )
        } else if (isFeature(component)) {
          return (
            <FeatureResolver
              component={component}
              key={index}
              components={components}
              path={path}
              index={index}
            />
          )
        } else if (
          isOpinion(component) &&
          // Proceed only if there's no Opinion component before. If it's there
          // then that means it was already accounted for.
          (!components[index - 1] || !isOpinion(components[index - 1]))
        ) {
          return (
            <OpinionsSlider
              opinions={accumulateOpinions(components.slice(index))}
              key={index}
            />
          )
        } else if (isLogosShowcase(component)) {
          // TODO: need a better way of deciding this inverted style.
          return (
            <LogosShowcase
              {...component}
              key={index}
              inverted={components[index - 1] && isQuote(components[index - 1])}
            />
          )
        } else if (
          isQuote(component) &&
          (!components[index - 1] || !isQuote(components[index - 1]))
        ) {
          return (
            <Quotes
              quotes={accumulateQuotes(components.slice(index))}
              key={index}
            />
          )
        } else if (
          isSmallCard(component) &&
          (!components[index - 1] || !isSmallCard(components[index - 1]))
        ) {
          return (
            <SmallCardsContainer
              cards={accumulateSmallCards(components.slice(index))}
              key={index}
            />
          )
        } else if (
          isNormalCard(component) &&
          (!components[index - 1] || !isNormalCard(components[index - 1]))
        ) {
          return (
            <Cards
              cards={accumulateNormalCards(components.slice(index))}
              key={index}
            />
          )
        } else if (
          isMicroFeature(component) &&
          (!components[index - 1] || !isMicroFeature(components[index - 1])) &&
          (!components[index - 1] ||
            !(
              isFeature(components[index - 1]) &&
              isBlankFeature(components[index - 1] as FeatureComponent)
            ))
        ) {
          return (
            <MicroFeature
              items={accumulateMicroFeature(components.slice(index))}
              key={index}
            />
          )
        } else if (isContactForm(component)) {
          return <ContactForm {...component} key={index} />
        } else if (isPost(component)) {
          return (
            <Post
              {...component}
              content={component.text.childMarkdownRemark.htmlAst}
              key={index}
              socialSharesElement={<SocialShare path={path} />}
            />
          )
        } else if (isPostsListing(component)) {
          return <PostsListing posts={[]} {...component} key={index} />
        } else if (isCallToAction(component)) {
          return (
            <CallToAction
              {...component}
              buttonTarget={
                component.buttonTarget ? component.buttonTarget.path : ""
              }
              key={index}
            />
          )
        } else if (isHomePageMap(component)) {
          return <Map key={index} />
        } else if (isTagHeader(component)) {
          return <TagsHeader key={index} {...component} />
        } else if (isDocument(component)) {
          return (
            <Document
              html={component.text.childMarkdownRemark.html}
              key={index}
            />
          )
        } else if (isVideo(component)) {
          return <Video {...component} key={index} />
        } else if (isNewsletter(component)) {
          return <Newsletter {...component} key={index} />
        } else if (isPagination(component)) {
          return <Pagination {...component} key={index} />
        } else if (isImage(component)) {
          return <Image {...component} key={index} />
        } else if (isUniqueComponent(component)) {
          return <UniqueComponentResolver {...component} key={index} />
        } else if (isButton(component)) {
          return (
            <Button {...component} key={index}>
              {component.label}
            </Button>
          )
        } else if (isPasswordResetIcon(component)) {
          return <PasswordResetIcon {...component} key={index} />
        } else if (isPasswordResetForm(component)) {
          return <PasswordResetForm {...component} key={index} />
        } else if (isPostAuthorHeader(component)) {
          return <PostAuthor key={index} {...component} />
        } else if (isEmptyListing(component)) {
          return <EmptyListing key={index} {...component} />
        } else {
          return null
        }
      })}
    </>
  )
}

export default Resolver
