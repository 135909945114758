import { Link } from "gatsby"
import RCPagination from "rc-pagination"
import React, { ReactNode, useEffect, useState } from "react"
import { FunctionComponent } from "react"
import styled from "styled-components"
import ArrowRight from "../../../images/point-arrow.svg"
import { supportColor, textColor } from "../../../theming/theme-getters"
import PaginationContent from "./pagination.content"

const ArrowLeft = styled(ArrowRight)`
  transform: rotate(-180deg);
`

const Root = styled(RCPagination)`
  padding: 0 0 5rem;
  list-style: none;
  display: flex;
  justify-content: center;

  li {
    height: 35px;
    width: 35px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    a {
      text-decoration: none;
      display: block;
      height: 35px;
      width: 35px;
    }
  }
  .rc-pagination {
    &-disabled {
      cursor: not-allowed;

      .arrow {
        stroke: ${textColor("paragraph")};
      }
    }

    &-prev,
    &-next {
      margin: 0 1.25rem;
    }

    &-item {
      a {
        height: 35px;
        width: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: ${textColor("primary")};
      }
      &-active {
        border-radius: 50%;
        background: ${supportColor("actionable")};

        a {
          color: ${textColor("inverted")};
        }
      }
    }

    &-jump-prev,
    &-jump-next {
      a {
        color: ${textColor("paragraph")};
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
`

const Pagination: FunctionComponent<PaginationContent> = ({
  total,
  pageSize,
  blogPath,
  path,
}) => {
  const [currentPage, setCurrentPage] = useState(1)
  useEffect(() => {
    const currentPath = path.replace(/\D+/g, "")
    if (currentPath === "") {
      setCurrentPage(1)
    } else {
      setCurrentPage(+currentPath)
    }
  }, [])
  const onChange = (page: number) => {
    setCurrentPage(page)
  }
  const itemRender = (current: ReactNode, type: string, element: ReactNode) => {
    if (type === "page") {
      if (current === 1 || current === 0) {
        return <Link to={`${blogPath}/`}>{current}</Link>
      }
      return <Link to={`${blogPath}/${current}/`}>{current}</Link>
    }
    if (type === "prev") {
      if (current === 1 || current === 0) {
        return (
          <Link to={`${blogPath}/`}>
            <ArrowLeft />
          </Link>
        )
      }
      return (
        <Link to={`${blogPath}/${current}/`}>
          <ArrowLeft />
        </Link>
      )
    }
    if (type === "next") {
      return (
        <Link to={`${blogPath}/${current}/`}>
          <ArrowRight />
        </Link>
      )
    }
    if (type === "jump-next") {
      return <Link to={`${blogPath}/${current}/`}>...</Link>
    }
    if (type === "jump-prev") {
      if (current === 1 || current === 0) {
        return <Link to={`${blogPath}/`}>...</Link>
      }
      return <Link to={`${blogPath}/${current}/`}>...</Link>
    }
    return element
  }

  return (
    <Root
      itemRender={itemRender}
      showTitle={false}
      total={total}
      pageSize={pageSize}
      current={currentPage}
      onChange={onChange}
    />
  )
}

export default Pagination
