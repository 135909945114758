import { graphql, useStaticQuery } from "gatsby"
import * as React from "react"
import { FacebookShareButton, TwitterShareButton } from "react-share"
import styled from "styled-components"
import Facebook from "../../../images/facebook.svg"
import Twitter from "../../../images/twitter.svg"

interface Props {
  path: string
}

const SocialShareWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 2rem 0;

  @media (min-width: 73.75rem) {
    flex-direction: column;
    margin: 0;
  }

  & > * {
    margin-right: 1.25rem;
    cursor: pointer;

    @media (min-width: 73.75rem) {
      margin-right: 0;
      margin-bottom: 2rem;
    }
  }
`

export const SocialShareView: React.FunctionComponent<
  Props & {
    siteUrl: string
  }
> = ({ path, siteUrl }) => {
  return (
    <SocialShareWrapper>
      <FacebookShareButton url={`${siteUrl}${path}`}>
        <Facebook />
      </FacebookShareButton>
      <TwitterShareButton url={`${siteUrl}${path}`}>
        <Twitter />
      </TwitterShareButton>
    </SocialShareWrapper>
  )
}

const SocialShare: React.FunctionComponent<Props> = (props) => {
  const {
    site: {
      siteMetadata: { siteUrl },
    },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `
  )
  return <SocialShareView {...props} siteUrl={siteUrl} />
}

export default SocialShare
