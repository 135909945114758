import { Link } from "gatsby"
import React, { FunctionComponent, useState } from "react"
import styled, { css } from "styled-components"
import VooomLogo from "../../../images/vooom-logo-color.svg"
import VooomLogoMono from "../../../images/vooom-logo-mono.svg"
import {
  backgroundColor,
  mainGradient,
  textColor,
} from "../../../theming/theme-getters"
import NavigationItem from "../../../types/navigation-item.interface"
import LayoutBlock from "../../layout/layout-block.component"
import StoreButtons from "../store-buttons/store-buttons.component"
import calculateBreakpointsForMenu from "./calculate-breakpoints"
import { NavLinkStyle } from "../../global-style.component"
import ScrollLock from "react-scrolllock"
import { localPath } from "../../../functions/path"
import { FormattedMessage, useIntl } from "react-intl"

interface Props {
  navigationItems: NavigationItem[]
}

const HeaderContainer = styled.header`
  margin-bottom: 2rem;
  background-image: linear-gradient(
      to bottom,
      ${mainGradient("overlay")},
      ${mainGradient("overlay")}
    ),
    radial-gradient(
      circle at 107% 109%,
      ${mainGradient("start")},
      ${mainGradient("middle")} 51%,
      ${mainGradient("end")}
    );
  color: ${textColor("inverted")};
`

const HeaderLink = styled(Link)`
  ${NavLinkStyle};
  color: inherit;
`

const HeaderLinkExternal = styled.a`
  ${NavLinkStyle};
  color: inherit;
`

const HeaderNav = styled.nav`
  font-size: 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.875rem 0;
`

const MainMenu = styled.div<{ open: boolean }>`
  transition: opacity 1s;
  ${({ open }) =>
    open
      ? css`
          z-index: 12;
          position: fixed;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          width: 100vw;
          height: 100vh;
          background: ${backgroundColor("footer")};
          opacity: ${open ? 1 : 0};
          overflow: hidden;
          overflow-y: scroll;
        `
      : css`
          margin-left: auto;
        `}
`

const MenuItem = styled.li<{
  column: boolean
  hideOnBreakpoint?: number
}>`
  margin: 0 1.9rem;
  text-align: ${({ column }) => (column ? "center" : "left")};
  display: ${({ column }) => (column ? "block" : "none")};
  ${({ hideOnBreakpoint }) =>
    hideOnBreakpoint &&
    css`
      @media (min-width: ${hideOnBreakpoint}rem) {
        display: block;
      }
    `}
  }
`

const MenuList = styled.ul<{ column: boolean }>`
  padding-left: 0;
  list-style: none;
  margin: 0;
  display: flex;
  justify-content: space-between;

  ${({ column }) =>
    column &&
    css`
      margin-top: 3rem;
      flex-direction: column;
      color: ${textColor("dark")};

      ${MenuItem} {
        margin-bottom: 1rem;
      }
    `}
`

const LogoLink = styled(Link)`
  line-height: 1;
`

const Logo = css`
  height: 80px;
  width: 80px;

  @media (min-width: 48rem) {
    width: 100px;
    height: 100px;
  }
`

const LogoMono = styled(VooomLogoMono)`
  ${Logo}
`

const LogoColor = styled(VooomLogo)`
  ${Logo}
`

const NavigationToggler = styled.button.attrs({
  width: 30,
  height: 18,
  barHeight: 3,
})<{
  open: boolean
  startBreakpoint: number
  alwaysVisible: boolean
}>`
  cursor: pointer;
  position: relative;
  width: ${({ width }) => width}px;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  padding: 0;
  border: none;
  background: none;
  color: ${({ open, theme }) =>
    open
      ? backgroundColor("dark")({ theme })
      : backgroundColor("light")({ theme })};
  outline: none;

  display: block;
  ${({ alwaysVisible, startBreakpoint }) =>
    !alwaysVisible &&
    css`
      @media (min-width: ${startBreakpoint}rem) {
        display: none;
      }
    `}

  &::before,
  &::after {
    content: "";
    position: absolute;
    left: 0;
    display: block;
    width: 100%;
    height: ${({ barHeight }) => barHeight}px;
    background: currentColor;
    transition: all 150ms;
  }

  &::before {
    top: 0;
    ${({ open }) =>
      open &&
      css`
        transform: translateY(
            ${({ height }: { height: number }) => height / 2}px
          )
          rotate(45deg);
      `}
  }

  &::after {
    box-shadow: 0
      ${({ height, barHeight }) => -(height / 2 - Math.floor(barHeight / 2))}px
      0 ${({ open }) => (open ? "transparent" : "currentColor")};
    bottom: 0;
    ${({ open }) =>
      open &&
      css`
        transform: translateY(
            -${({ height, barHeight }: { height: number; barHeight: number }) => height / 2 - Math.ceil(barHeight / 2) - 1}px
          )
          rotate(-45deg);
      `}
  }
`

const MenuTag = styled.span<{ visible: boolean }>`
  position: absolute;
  top: -0.25rem;
  right: 3rem;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 150ms;
  pointer-events: ${({ visible }) => (visible ? "auto" : "none")};
`

const Box = styled.div<{ open: boolean }>`
  position: ${({ open }) => (open ? "fixed" : "relative")};
  right: ${({ open }) => (open ? "20px" : "0")};
  order: 3;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  z-index: 13;
  margin-left: 2rem;
  color: ${textColor("dark")};
`

const Separator = styled.div`
  height: 1.875rem;
  width: 1px;
  background-color: rgba(18, 27, 38, 0.1);
  margin: 0 1.875rem;
`

const MobileLogoContainer = styled.div<{ visible: boolean }>`
  position: ${({ visible }) => (visible ? "fixed" : "absolute")};
  left: 1rem;
  max-width: ${({ theme }) => theme.layout.width};
  margin: 1.875rem auto 0;
  line-height: 1;
  pointer-events: none;
  visibility: ${({ visible }) => (visible ? "visible" : "hidden")};
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 1s, visibility 0ms;
  z-index: 2;
`

const StoreButtonsContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: center;
`

const OverlayStoreButtons = styled(StoreButtons)`
  flex-direction: column;
`

const MenuWrapper = styled.div<{ visible: boolean }>`
  margin-top: ${({ visible }) => (visible ? "105px" : "0")};
  overflow: ${({ visible }) => (visible ? "auto" : "initial")};

  @media (min-width: 48rem) {
    margin-top: ${({ visible }) => (visible ? "125px" : "0")};
  }
`

const MobileHeaderOverlay = styled.div`
  background: ${backgroundColor("footer")};
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 105px;

  @media (min-width: 48rem) {
    height: 125px;
  }
`

const Header: FunctionComponent<Props> = ({
  navigationItems = [],
  children,
}) => {
  const [open, setOpen] = useState(false)
  const { breakpoints, overflown } = calculateBreakpointsForMenu(
    navigationItems
  )
  const toggleThreshold = breakpoints[breakpoints.length - 1]
  const toggleNav = () => setOpen(!open)
  const intl = useIntl()
  return (
    <HeaderContainer>
      <LayoutBlock>
        <HeaderNav>
          <LogoLink
            to={"/"}
            title={intl.formatMessage({ id: "back.to.homepage" })}
          >
            <LogoMono />
          </LogoLink>
          <Box open={open}>
            <NavigationToggler
              onClick={toggleNav}
              open={open}
              startBreakpoint={toggleThreshold}
              alwaysVisible={overflown}
            >
              <MenuTag visible={open} />
            </NavigationToggler>
            {open && <Separator />}
            {open && (
              <HeaderLinkExternal
                href={
                  process.env.GATSBY_SITE_URL === "https://vooom.pl/"
                    ? "https://en.vooom.pl"
                    : "https://vooom.pl/"
                }
              >
                <FormattedMessage id="switch.language" />
              </HeaderLinkExternal>
            )}
          </Box>
          <ScrollLock isActive={open}>
            <MainMenu open={open}>
              <MobileLogoContainer visible={open}>
                <LogoColor />
              </MobileLogoContainer>
              {open && <MobileHeaderOverlay />}
              <MenuWrapper visible={open}>
                <MenuList column={open}>
                  {navigationItems.map(({ navigationName, path }, index) => (
                    <MenuItem
                      key={path}
                      column={open}
                      hideOnBreakpoint={breakpoints[index]}
                    >
                      {path.startsWith("http") ? (
                        <HeaderLinkExternal
                          href={path}
                          target="_blank"
                          rel="noreferrer noopener nofollow"
                        >
                          {navigationName}
                        </HeaderLinkExternal>
                      ) : (
                        <HeaderLink
                          partiallyActive={path !== "/"}
                          to={localPath(path)}
                          activeClassName="active"
                        >
                          {navigationName}
                        </HeaderLink>
                      )}
                    </MenuItem>
                  ))}
                </MenuList>
                {open && (
                  <StoreButtonsContainer>
                    <OverlayStoreButtons />
                  </StoreButtonsContainer>
                )}
              </MenuWrapper>
            </MainMenu>
          </ScrollLock>
        </HeaderNav>
        {children}
      </LayoutBlock>
    </HeaderContainer>
  )
}

export default Header
