import React from "react"
import styled from "styled-components"
import ReactSlick, { Settings } from "react-slick"
import AppFunctionComponent from "../../../types/app-function-component.interface"

import "./slick.css"
import "./slick-theme.css"

const Container = styled(ReactSlick)``

interface Props {
  readonly settings: Settings
}

const Slider: AppFunctionComponent<Props> = ({
  children,
  className,
  settings,
}) => {
  return (
    <Container {...settings} className={className}>
      {children}
    </Container>
  )
}

export default Slider
