import React from "react"
import styled from "styled-components"
import text from "../../../functions/text"
import AppFunctionComponent from "../../../types/app-function-component.interface"
import ColorSquare, {
  ColorSquareVariant,
} from "../color-squares/color-square.component"
import ColorSquaresGroup from "../color-squares/color-squares-group.component"

const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
`

const Emphasis = styled.em`
  font-size: 3.375rem;
  font-style: normal;
`

interface Props {
  readonly features: readonly string[]
}

const colors = [
  ColorSquareVariant.ViolentViolet,
  ColorSquareVariant.PoliticalBlue,
  ColorSquareVariant.CrazyOrange,
]

const InfoSection: AppFunctionComponent<Props> = ({ features }) => {
  const limitedFeatures = features.slice(0, colors.length)
  return (
    <ColorSquaresGroup>
      {limitedFeatures.map((feature, index) => {
        const [title, emphasis] = feature.split("|")
        return (
          <ColorSquare variant={colors[index]} key={index}>
            <Content>
              {text(title)}
              <Emphasis>{text(emphasis)}</Emphasis>
            </Content>
          </ColorSquare>
        )
      })}
    </ColorSquaresGroup>
  )
}

export default InfoSection
