import styled from "styled-components"

const LayoutBlock = styled.section`
  max-width: ${({
    theme: { layout: { width } = { width: "100%" } } = {
      layout: { width: "100%" },
    },
  }) => width};
  padding: 0 1rem;
  margin-left: auto;
  margin-right: auto;
`

export default LayoutBlock
