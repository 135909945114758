import GatsbyImage from "gatsby-image"
import React from "react"
import styled from "styled-components"
import AppFunctionComponent from "../../../types/app-function-component.interface"
import CardContainer from "../../base/card-container/card-container"
import CardContent from "./card-content.interface"

export const CardBox = styled.div``
export const Image = styled(GatsbyImage)``

const Card: AppFunctionComponent<CardContent> = ({
  title,
  content,
  image,
  linkText,
  linkUrl,
  media,
  className = "",
}) => {
  const withLink = linkText && linkUrl
  return (
    <CardContainer className={className}>
      {image && <Image fluid={image.fluid} alt={image.description} />}
      <CardBox>
        <h4 data-testid="title">{title}</h4>
        {content && <p>{content}</p>}
        {withLink && <a href={linkUrl}>{linkText}</a>}
        {linkText && media && <a href={media.file.url}>{linkText}</a>}
      </CardBox>
    </CardContainer>
  )
}

export default Card
