import React from "react"
import { TagsContent } from "./tags.interface"
import { Link } from "gatsby"
import styled from "styled-components"
import { backgroundColor, textColor } from "../../../theming/theme-getters"
import AppFunctionComponent from "../../../types/app-function-component.interface"

const Root = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: -0.75rem;
`

const Tag = styled(Link)`
  background-color: ${backgroundColor("quote")};
  color: ${textColor("inverted")};
  font-size: ${16 / 16}rem;
  font-weight: 100;
  padding: 0.3125rem 0.9375rem;
  border-radius: 3px;
  text-decoration: none;
  margin-right: 0.75rem;
  margin-bottom: 0.75rem;
  line-height: 1;

  &:last-child {
    margin-right: 0;
  }
`

const Tags: AppFunctionComponent<TagsContent> = ({ className, fields }) => {
  return (
    <Root className={className}>
      {fields?.tagsWithSlugs.map((tag) => (
        <Tag key={tag.name} to={`/blog/tags/${tag.slug}`}>
          {tag.name}
        </Tag>
      ))}
    </Root>
  )
}

export default Tags
