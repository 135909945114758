import React, { FunctionComponent, ReactNode, useEffect, useState } from "react"
import styled from "styled-components"
import { textColor } from "../../../theming/theme-getters"
import { TimeContent } from "./time.interface"
import dayjs from "dayjs"
import "dayjs/locale/pl"
import "dayjs/locale/en"
import relativeTime from "dayjs/plugin/relativeTime"
import updateLocale from "dayjs/plugin/updateLocale"
dayjs.extend(relativeTime)
dayjs.extend(updateLocale)

const Box = styled.span`
  display: flex;
  flex-direction: column;
  transition: transform 1s ease-in-out;
`

const Root = styled.div`
  overflow: hidden;

  p {
    font-size: 1rem;
    line-height: 1.5;
    margin: 0;
    overflow: hidden;
    height: 1.5rem;
    @media (min-width: 73.75rem) {
      font-size: 1.25rem;
      height: 1.875rem;
    }
  }
`

const Wrapper = styled.div<{ loaded: boolean }>`
  height: 1.5rem;
  transition: transform 1s ease-in-out;
  transform: translateY(${({ loaded }) => (loaded ? "-1.5rem" : "0")});

  @media (min-width: 73.75rem) {
    height: 1.875rem;
    transform: translateY(${({ loaded }) => (loaded ? "-1.875rem" : "0")});
  }
`

const Date = styled.p`
  font-size: 1rem;
  font-weight: 300;
  color: ${textColor("paragraph")};
  line-height: 1.5;
  height: 1.5rem;

  &:hover {
    ${Box} {
      transform: translateY(-1.5rem);

      @media (min-width: 73.75rem) {
        transform: translateY(-1.875rem);
      }
    }
  }

  @media (min-width: 73.75rem) {
    font-size: 1.25rem;
    height: 1.875rem;
  }
`

dayjs.updateLocale("pl", {
  months: [
    "Stycznia",
    "Lutego",
    "Marca",
    "Kwietnia",
    "Maja",
    "Czerwca",
    "Lipca",
    "Sierpnia",
    "Września",
    "Października",
    "Listopada",
    "Grudnia",
  ],
})

const Time: FunctionComponent<TimeContent & { className?: string }> = ({
  date,
  className,
}) => {
  const locale = process.env.GATSBY_SITE_LANG === "pl" ? "pl" : "en"
  const [time, setTime] = useState<string | ReactNode>(date)
  const [loaded, setLoaded] = useState<boolean>(false)
  const relativeDate = dayjs(date).locale(locale).fromNow()
  const formattedDate = dayjs(date).locale(locale).format("DD MMMM YYYY, HH:mm")
  useEffect(() => {
    const properDate =
      dayjs().subtract(2, "day") > dayjs(date) ? (
        formattedDate
      ) : (
        <Box>
          <span>{relativeDate}</span>
          <span>{formattedDate}</span>
        </Box>
      )

    setTime(properDate)
    setLoaded(true)
  }, [])

  return (
    <Root>
      <Wrapper loaded={loaded}>
        <p />
        <Date className={className}>{time}</Date>
      </Wrapper>
    </Root>
  )
}

export default Time
