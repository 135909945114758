import React from "react"
import { PostAuthorContent } from "./post-author.interface"
import GatsbyImage from "gatsby-image"
import styled, { css } from "styled-components"
import { backgroundColor, textColor } from "../../../theming/theme-getters"
import { Link } from "gatsby"
import AvatarIcon from "../../../images/avatar.svg"
import SocialLinks from "../../common/social-links/social-links.component"
import AppFunctionComponent from "../../../types/app-function-component.interface"
import { FormattedMessage } from "react-intl"
import { fullAuthorPath } from "../../../functions/full-author-path"

const Root = styled.div`
  padding: 3.75rem 1.25rem;
  background: ${backgroundColor("quote")};
  color: ${textColor("inverted")};

  @media (min-width: 73.75rem) {
    padding: 3.75rem 0 5.625rem;
  }
`

export const AuthorContainer = styled.div`
  max-width: 73.75rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 73.75rem) {
    align-items: flex-start;
  }
`

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 73.75rem) {
    flex-direction: row;
    width: 100%;
  }
`

const NameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 73.75rem) {
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }
`

export const Name = styled(Link)`
  color: ${textColor("inverted")};
  font-size: ${32 / 16}rem;
  line-height: ${44 / 32};
  text-decoration: none;
  display: block;

  @media (min-width: 73.75rem) {
    font-size: ${42 / 16}rem;
  }
`

const StyledAvatar = css`
  height: 6rem;
  width: 6rem;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  margin-bottom: 1.25rem;

  @media (min-width: 73.75rem) {
    margin-bottom: 0;
    height: 7.5rem;
    width: 7.5rem;
    margin-right: 1.875rem;
  }
`

export const Avatar = styled(GatsbyImage)`
  ${StyledAvatar}
`

export const AvatarPlaceholder = styled.div`
  background: ${backgroundColor("light")};
  display: flex;
  align-items: center;
  justify-content: center;
  ${StyledAvatar}
`

const SocialWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 2.5rem 0;

  @media (min-width: 73.75rem) {
    margin: 0;
  }
`

const StyledSocialLinks = styled(SocialLinks)`
  margin-right: 1.875rem;

  &:last-child {
    margin-right: 0;
  }

  svg {
    path {
      fill: ${textColor("inverted")};
    }

    &:hover {
      path {
        fill: ${textColor("paragraph")};
      }
    }
  }
`

export const Bio = styled.div`
  max-width: 31.25rem;
  text-align: center;
  font-weight: 100;

  @media (min-width: 73.75rem) {
    margin-top: 1.875rem;
    max-width: max-content;
    text-align: left;
    font-size: ${20 / 16}rem;
  }
`

export const Title = styled.p`
  font-size: ${20 / 16}rem;
  margin: -1.875rem 0 1.875rem;
  font-weight: 600;

  @media (min-width: 73.75rem) {
    font-size: ${42 / 16}rem;
  }
`

const PostAuthor: AppFunctionComponent<PostAuthorContent> = ({
  name,
  bio,
  authorPath,
  socialLinks,
  image,
  title = true,
  className,
}) => {
  return (
    <Root className={className}>
      <AuthorContainer>
        {title && (
          <Title>
            <FormattedMessage id="post.author.title" />
          </Title>
        )}
        <Box>
          {image?.fluid ? (
            <Link to={fullAuthorPath(authorPath)} data-testid="author-avatar">
              <Avatar fluid={image.fluid} alt={image.description} />
            </Link>
          ) : (
            <Link to={fullAuthorPath(authorPath)}>
              <AvatarPlaceholder data-testid="author-avatar-placeholder">
                <AvatarIcon />
              </AvatarPlaceholder>
            </Link>
          )}
          <NameWrapper>
            <Name to={fullAuthorPath(authorPath)}>{name}</Name>
            {socialLinks && (
              <SocialWrapper data-testid="author-sociallinks">
                <StyledSocialLinks socialLinks={socialLinks} />
              </SocialWrapper>
            )}
          </NameWrapper>
        </Box>
        <Bio>{bio.bio}</Bio>
      </AuthorContainer>
    </Root>
  )
}

export default PostAuthor
