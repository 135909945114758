import React, { ChangeEvent, FormEvent, FunctionComponent } from "react"
import styled from "styled-components"
import text from "../../../functions/text"
import { backgroundColor, textColor } from "../../../theming/theme-getters"
import PendableButton from "../../base/button/pendable-button.component"
import Label from "../../base/label/label.component"
import TextInput from "../../base/text-input/text-input.component"
import LayoutBlock from "../../layout/layout-block.component"
import Headline from "../headline/headline.component"
import NewsletterContent from "./newsletter-content.interface"

const Container = styled.div`
  overflow: hidden;
  padding: 6rem 0 1rem;
  background: ${backgroundColor("dark")};
`

const Form = styled.form`
  display: flex;
  flex-direction: column;

  @media (min-width: 43.75rem) {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`

const FormLabel = styled(Label)`
  margin-bottom: 2rem;
  @media (min-width: 43.75rem) {
    margin-bottom: 0;
    margin-right: -3rem;
    padding-right: 4rem;
    width: 30%;
    min-width: 300px;
  }
`

const LegalNote = styled.p`
  margin-top: 3rem;
  margin-bottom: 1.5rem;
  text-align: center;
  color: ${textColor("paragraph")};
  @media (min-width: 43.75rem) {
    margin-top: 2rem;
    margin-left: auto;
    margin-right: auto;
    font-size: 0.675rem;
    max-width: 50%;
  }
`

const Message = styled.p`
  text-align: center;
  color: ${textColor("inverted")};
`

const Error = styled.p`
  text-align: center;
  color: ${textColor("error")};
`

interface Props extends NewsletterContent {
  readonly onSubmit?: () => void
  readonly onEmailChange?: (email: string) => void
  readonly pending?: boolean
  readonly value?: string
  readonly error?: string
  readonly done?: boolean
}

const NewsletterForm: FunctionComponent<Props> = ({
  onSubmit = () => null,
  onEmailChange = () => null,
  pending = false,
  value = "",
  title,
  copy,
  label,
  successMessage,
  placeholder,
  buttonText,
  legal,
  error,
  done = false,
}) => {
  const onFormSubmit = (event: FormEvent) => {
    event.preventDefault()
    if (!pending) {
      onSubmit()
    }
  }
  const onInputChange = ({
    target: { value: inputValue },
  }: ChangeEvent<HTMLInputElement>) => onEmailChange(inputValue)
  return (
    <Container>
      <LayoutBlock>
        {title && <Headline inverted={true} title={title} paragraph={copy} />}
        <Form onSubmit={onFormSubmit} data-testid="form">
          <FormLabel text={text(label)} variant="stylish">
            <TextInput
              data-testid="input"
              type="email"
              onChange={onInputChange}
              value={value}
              required
              placeholder={text(placeholder)}
            />
          </FormLabel>
          <PendableButton pending={pending} data-testid="submit-button">
            {text(buttonText)}
          </PendableButton>
        </Form>
        {error && <Error>{error}</Error>}
        {done && <Message>{text(successMessage)}</Message>}
        <LegalNote>{text(legal)}</LegalNote>
      </LayoutBlock>
    </Container>
  )
}

export default NewsletterForm
