import React, { useEffect, useState } from "react"
import Slider from "../slider/slider.component"
import AppFunctionComponent from "../../../types/app-function-component.interface"
import { ArrowLeft, ArrowRight } from "../slider/slider-arrows.component"
import styled from "styled-components"

const settings = {
  className: "center",
  centerMode: true,
  infinite: true,
  centerPadding: "60px",
  slidesToShow: 1,
  nextArrow: <ArrowRight />,
  prevArrow: <ArrowLeft />,

  responsive: [
    {
      breakpoint: 500,
      settings: {
        centerPadding: "30px",
      },
    },
    {
      breakpoint: 400,
      settings: {
        centerPadding: "10px",
      },
    },
  ],
}

const StyledSlider = styled(Slider)`
  margin-bottom: -2px;

  .slick-next {
    right: 0;
  }

  .slick-prev {
    left: 0;
  }

  .slick-track {
    padding-bottom: 2rem;
  }
`

const SmallCardsSlider: AppFunctionComponent = ({ children, className }) => {
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
  }, [])
  return (
    <>
      {loading && (
        <StyledSlider className={className} settings={settings}>
          {children}
        </StyledSlider>
      )}
    </>
  )
}

export default SmallCardsSlider
