import React, { FunctionComponent, useEffect, useState } from "react"
import styled from "styled-components"
import { backgroundColor, textColor } from "../../../theming/theme-getters"
import CardContainer from "../../base/card-container/card-container"
import Rating from "../../common/rating/rating.component"
import Slider from "../../common/slider/slider.component"
import LayoutBlock from "../../layout/layout-block.component"
import OpinionContent from "./opinion-content.interface"
import {
  ArrowLeft,
  ArrowRight,
} from "../../common/slider/slider-arrows.component"

interface Props {
  readonly opinions: readonly OpinionContent[]
}

const settings = {
  infinite: false,
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: true,
  nextArrow: <ArrowRight />,
  prevArrow: <ArrowLeft />,
  responsive: [
    {
      breakpoint: 1100,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 750,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        className: "center",
      },
    },
  ],
}

const Container = styled.div`
  padding-bottom: 2rem;
  background: linear-gradient(
    0deg,
    ${backgroundColor("dark")} 0%,
    ${backgroundColor("dark")} 55%,
    transparent 55%,
    transparent 100%
  );

  @media (min-width: 48rem) {
    padding-bottom: 4rem;
  }
`

const SliderContainer = styled(Slider)`
  box-sizing: border-box;

  .slick-slide {
    opacity: 0;
    transition: opacity 0.3s ease-out;

    &.slick-active {
      opacity: 1;
      transition: opacity 0.3s ease-in;
    }
  }

  .slick-disabled {
    display: none;
  }
`

const Opinion = styled(CardContainer)`
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  outline: none;

  @media (min-width: 53.125rem) {
    flex-grow: 1;
    flex-basis: 0;
    display: flex !important;
    flex-direction: column;
  }
`

const Title = styled.h4`
  font-size: 1.25rem;
  font-weight: 300;
`

const Content = styled.p`
  margin-top: 0;
  font-size: 1.25rem;
  color: ${textColor("paragraph")};
`

const Author = styled.aside`
  margin-top: auto;
  color: ${textColor("paragraph")};
`

const OpinionsSlider: FunctionComponent<Props> = ({ opinions = [] }) => {
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
  }, [])

  return (
    <Container>
      <LayoutBlock>
        {loading && (
          <SliderContainer settings={settings}>
            {opinions.map(({ rating, title, content, author }, index) => (
              <Opinion key={index} data-testid="slide">
                <Rating rating={rating} />
                <Title data-testid="title">{title}</Title>
                <Content data-testid="content">{content}</Content>
                <Author data-testid="author">{author}</Author>
              </Opinion>
            ))}
          </SliderContainer>
        )}
      </LayoutBlock>
    </Container>
  )
}

export default OpinionsSlider
