import styled from "styled-components"
import ColorSquare from "./color-square.component"

const ColorSquaresGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  ${ColorSquare} {
    @media (max-width: 43.74rem) {
      margin-top: 1rem;
      height: 200px !important;
    }
  }

  @media (min-width: 43.75rem) {
    display: grid;
    grid-template-columns: repeat(2, 280px);
    grid-template-rows: repeat(4, 1fr);
    grid-gap: 20px;

    > :nth-child(1) {
      grid-row: 1 / 3;
    }

    > :nth-child(2) {
      grid-row: 2 / 4;
    }

    > :nth-child(3) {
      grid-row: 3 / 5;
    }
  }
`

export default ColorSquaresGroup
