import React, { FunctionComponent, ReactNode } from "react"
import FooterWithNavigationAndMedia from "../common/footer-with-navigation-and-media/footer-with-navigation-and-media.component"
import Header from "../common/header/header.component"
import { useNavigationLinks } from "../hooks/use-navigation-links"
import RootLayout, { LayoutProps } from "./root-layout.component"

interface Props extends LayoutProps {
  readonly headerSubSection: ReactNode
}

const LandingLayout: FunctionComponent<Props> = (props) => {
  const { headerSubSection, children, footerAdditionalBackground } = props
  const { mainLinks, footerLinks, asideFooterLinks } = useNavigationLinks()
  return (
    <RootLayout {...props}>
      <Header navigationItems={mainLinks}>{headerSubSection}</Header>
      <main>{children}</main>
      <FooterWithNavigationAndMedia
        additionalBackground={footerAdditionalBackground}
        mainNavigationItems={footerLinks}
        asideNavigationItems={asideFooterLinks}
      />
    </RootLayout>
  )
}

export default LandingLayout
