import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react"
import { SiteConfigContext } from "../../context/site-config/site-config.context"
import CookiesView from "./cookies-view.component"

const useCookiesPolicy = () => {
  const [active, setActive] = useState(false)
  const deactivate = () => {
    const expirationDate = new Date()
    expirationDate.setFullYear(expirationDate.getFullYear() + 1)
    document.cookie = `cookies-policy=accepted; path=/; expires=${expirationDate.toUTCString()}`
    setActive(false)
  }

  useEffect(() => {
    if (document.cookie.includes("cookies-policy=accepted")) {
      setActive(false)
    } else {
      setActive(true)
    }
  }, [])

  return { active, deactivate }
}

const Cookies: FunctionComponent = () => {
  const { privacyPolicyLink } = useContext(SiteConfigContext)
  const { active, deactivate } = useCookiesPolicy()
  return (
    <CookiesView
      active={active}
      onAccept={deactivate}
      termsLink={privacyPolicyLink.path}
    />
  )
}

export default Cookies
