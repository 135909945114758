import { navigate } from "gatsby-link"
import React, { FunctionComponent, useState } from "react"
import ResetPasswordFormUI, {
  Props as FormUIProps,
} from "./password-reset-form-ui.component"
import PasswordResetFormContent from "./password-reset-form.content"

type Props = PasswordResetFormContent & Partial<FormUIProps>

const PasswordResetForm: FunctionComponent<Props> = ({
  code,
  email,
  ...props
}) => {
  const [pending, setPendingState] = useState(false)
  const [password, setPassword] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  const changePasswordRequest = () => {
    setPendingState(true)
    fetch(`${process.env.GATSBY_API_URL}/v1/auth/password`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        code,
        email,
        password,
      }),
    })
      .then((res: Response) => {
        if (!res.ok) {
          if (res.status === 400) {
            navigate("/auth/reset-password-timeout")
          } else {
            setErrorMessage("Coś poszło nie tak. Spróbuj za kilka chwil.")
          }
        } else {
          navigate(`/auth/reset-password-success?email=${email}`)
        }
      })
      .catch(() => {
        setErrorMessage("Coś poszło nie tak. Spróbuj za kilka chwil.")
      })
      .finally(() => setPendingState(false))
  }
  return (
    <ResetPasswordFormUI
      value={password}
      pending={pending}
      onInputChange={setPassword}
      onSubmit={changePasswordRequest}
      errorMessage={errorMessage}
      {...props}
    />
  )
}

export default PasswordResetForm
