import React, { FunctionComponent, useReducer, useState } from "react"
import ContactFormContent from "./contact-form-content.interface"
import {
  ContactInput,
  InputChangeAction,
  InputsValues,
} from "./contact-form-inputs.type"
import ContactFormView from "./contact-form-view.component"
import sendMessage from "./send-message"

const initialValues = {
  [ContactInput.Topic]: "",
  [ContactInput.Phone]: "",
  [ContactInput.Email]: "",
  [ContactInput.Message]: "",
  [ContactInput.Name]: "",
  [ContactInput.Agreements]: [],
} as const

const valuesReducer = (
  state: Readonly<InputsValues>,
  action: InputChangeAction
) => {
  if (action.input === ContactInput.Agreements) {
    return {
      ...state,
      [action.input]: state[action.input].map((value, valueIndex) =>
        valueIndex === action.index ? !value : value
      ),
    }
  } else {
    return {
      ...state,
      [action.input]: action.value,
    }
  }
}

const ContactForm: FunctionComponent<ContactFormContent> = ({
  topics,
  agreements,
  ...staticContactValues
}) => {
  const [subscriptionError, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [pending, setPending] = useState(false)
  const [state, dispatch] = useReducer(valuesReducer, {
    ...initialValues,
    [ContactInput.Topic]: topics[0],
    [ContactInput.Agreements]: agreements.map(() => false),
  })
  const handleSubmit = async () => {
    setPending(true)
    const { error, ok } = await sendMessage(state, agreements)
    setPending(false)
    setError(error)
    setSuccess(ok)
  }
  return (
    <ContactFormView
      values={state}
      onInputChange={dispatch}
      topics={topics}
      agreements={agreements}
      onSubmit={handleSubmit}
      pending={pending}
      done={success}
      error={subscriptionError}
      {...staticContactValues}
    />
  )
}

export default ContactForm
