import React, { FunctionComponent, useState } from "react"
import NewsletterContent from "./newsletter-content.interface"
import NewsletterForm from "./newsletter-form.component"
import subscribeToNewsletter from "./subscribe-to-newsletter"

const Newsletter: FunctionComponent<NewsletterContent> = (props) => {
  const [email, setEmail] = useState("")
  const [subscriptionError, setError] = useState("")
  const [success, setSuccess] = useState(false)
  const [pending, setPending] = useState(false)
  const onEmailChange = (emailValue: string) => setEmail(emailValue)

  const onSubmit = async () => {
    setPending(true)
    const { error, ok } = await subscribeToNewsletter(email)
    setPending(false)
    setError(error)
    setSuccess(ok)
  }
  return (
    <NewsletterForm
      {...props}
      onSubmit={onSubmit}
      onEmailChange={onEmailChange}
      value={email}
      pending={pending}
      done={success}
      error={subscriptionError}
    />
  )
}

export default Newsletter
