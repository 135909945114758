import { Link } from "gatsby"
import React, { FunctionComponent, useContext } from "react"
import styled from "styled-components"
import Facebook from "../../../images/facebook.svg"
import Instagram from "../../../images/instagram.svg"
import Linkedin from "../../../images/linkedin.svg"
import Twitter from "../../../images/twitter.svg"
import Youtube from "../../../images/yt.svg"
import { minBreakpoint, textColor } from "../../../theming/theme-getters"
import NavigationItem from "../../../types/navigation-item.interface"
import { SiteConfigContext } from "../../context/site-config/site-config.context"
import Footer, { Props as FooterProps } from "../footer/footer.component"
import Image, { FluidObject } from "gatsby-image"
import { NavLinkStyle } from "../../global-style.component"
import { localPath } from "../../../functions/path"
import { FormattedMessage } from "react-intl"

const SocialMedia = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 2rem;
`

const SocaialLink = styled.a`
  font-size: 0;
  & + & {
    margin-left: 2rem;
  }
`

const MainMenu = styled.ul`
  margin: 0 0 1rem;
  padding-left: 0;
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.25rem;
  flex-wrap: wrap;

  @media (min-width: ${minBreakpoint("tablet")}) {
    margin-top: 1.4rem;
    margin-bottom: 2.8rem;
  }
`

const MenuItem = styled.li`
  text-align: center;
  margin: 0 2rem 1rem;
`

const FooterLink = styled(Link)`
  ${NavLinkStyle};
  color: ${textColor("dark")};
`

const FooterLinkExternal = styled.a`
  ${NavLinkStyle};
  color: ${textColor("dark")};
`

const EUImage = styled(Image)`
  display: none;
  @media (min-width: ${minBreakpoint("mobile")}) {
    display: block;
  }
`

const EUImageMobile = styled(Image)`
  display: block;
  @media (min-width: ${minBreakpoint("mobile")}) {
    display: none;
  }
`

interface Props extends FooterProps {
  mainNavigationItems: NavigationItem[]
}

interface SocialMediaProps {
  twitterLink: string
  facebookLink: string
  instagramLink: string
  linkedinLink: string
  youtubeLink: string
}

interface EuLinksProps {
  euLink: string
  euLogos: { fluid: FluidObject }
  euLogosMobile: { fluid: FluidObject }
}

export const FooterWithNavigationAndMediaView: FunctionComponent<
  Props & SocialMediaProps & EuLinksProps
> = ({
  mainNavigationItems = [],
  instagramLink,
  facebookLink,
  twitterLink,
  youtubeLink,
  linkedinLink,
  euLink,
  euLogos,
  euLogosMobile,
  ...footerProps
}) => {
  return (
    <Footer {...footerProps}>
      <MainMenu>
        {mainNavigationItems.map(({ navigationName, path }) => (
          <MenuItem key={path}>
            {path.startsWith("http") ? (
              <FooterLinkExternal
                target="_blank"
                rel="noreferrer noopener nofollow"
                href={path}
              >
                {navigationName}
              </FooterLinkExternal>
            ) : (
              <FooterLink
                partiallyActive={path !== "/"}
                activeClassName="active"
                to={localPath(path)}
              >
                {navigationName}
              </FooterLink>
            )}
          </MenuItem>
        ))}
      </MainMenu>
      <SocialMedia>
        <SocaialLink href={instagramLink}>
          <FormattedMessage id="vooom.instagram" />
          <Instagram />
        </SocaialLink>
        <SocaialLink href={facebookLink}>
          <FormattedMessage id="vooom.facebook" />
          <Facebook />
        </SocaialLink>
        <SocaialLink href={twitterLink}>
          <FormattedMessage id="vooom.twitter" />
          <Twitter />
        </SocaialLink>
        <SocaialLink href={youtubeLink}>
          <FormattedMessage id="vooom.youtube" />
          <Youtube />
        </SocaialLink>
        <SocaialLink href={linkedinLink}>
          <FormattedMessage id="vooom.linkedin" />
          <Linkedin />
        </SocaialLink>
      </SocialMedia>
      <div>
        <a target="_blank" rel="noreferrer noopener nofollow" href={euLink}>
          <EUImage fluid={euLogos.fluid} />
          <EUImageMobile fluid={euLogosMobile.fluid} />
        </a>
      </div>
    </Footer>
  )
}

const FooterWithNavigationAndMedia: FunctionComponent<Props> = (props) => {
  const config = useContext(SiteConfigContext)
  return <FooterWithNavigationAndMediaView {...config} {...props} />
}

export default FooterWithNavigationAndMedia
