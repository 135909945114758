import React, { FunctionComponent } from "react"
import styled from "styled-components"
import { supportColor, textColor } from "../../../theming/theme-getters"
import { NormalCardComponent } from "../../../types/components.interface"
import LayoutBlock from "../../layout/layout-block.component"
import CardComponent, { CardBox, Image } from "../card/card.component"

const Container = styled(LayoutBlock)`
  max-width: 73.75rem;
  margin: 0 auto;

  @media (min-width: 35rem) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 20px;
  }
`

const Card = styled(CardComponent)`
  padding: 3.75rem 2.5rem 1.875rem;
  text-align: center;
  margin-bottom: 1.875rem;
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;

  ${CardBox} {
    display: flex;
    flex-direction: column;
    flex: 1 1 0%;
    margin: 0;
    overflow: hidden;
    justify-content: space-between;
  }

  ${Image} {
    width: 100px;
    height: 100px;
    margin: 0 auto 1.875rem;
  }

  h4 {
    font-weight: 300;
    font-size: 1.25rem;
    margin: 0;
  }

  p {
    color: ${textColor("paragraph")};
  }

  a {
    text-decoration: none;
    color: ${supportColor("actionable")};

    b,
    strong {
      color: ${supportColor("actionable")};
    }

    &:hover {
      text-decoration: underline;
    }
  }
`

interface Props {
  readonly cards: readonly NormalCardComponent[]
}

const Cards: FunctionComponent<Props> = ({ cards }) => {
  return (
    <Container>
      {cards.map((card, index) => (
        <Card {...card} key={index} />
      ))}
    </Container>
  )
}

export default Cards
